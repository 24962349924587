import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

interface Configuration {
  timezone: string;
  locale: string;
  loginUrl: string;
  clientId: string;
  logLevel: number;
  serverLogLevel: number;
  mloginHost: string;
}

const CONFIGURATION_KEY = 'Configuration';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements Configuration {
  private $apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  loadAppConfig() {
    return new Promise((resolve, reject) => {
      this.http.get<Configuration>(this.apiUrl + 'configuration').subscribe(
        (data) => {
          this.appConfig = data;
          resolve(true);
        },
        (error) => {
          if (error.status !== 504 || !this.appConfig) {
            reject(error);
          } else {
            resolve(false);
          }
        }
      );
    });
  }

  private get appConfig(): Configuration {
    return this.storage.get(CONFIGURATION_KEY);
  }

  private set appConfig(conf: Configuration) {
    this.storage.set(CONFIGURATION_KEY, conf);
  }

  get apiUrl() {
    return this.$apiUrl;
  }

  get timezone() {
    return this.appConfig.timezone;
  }

  get locale() {
    return this.appConfig?.locale;
  }

  get logLevel() {
    return this.appConfig.logLevel;
  }

  get serverLogLevel() {
    return this.appConfig.serverLogLevel;
  }

  get serverLoggingUrl() {
    return this.$apiUrl + 'log';
  }

  get loginUrl() {
    return this.appConfig.loginUrl;
  }

  get clientId() {
    return this.appConfig.clientId;
  }

  get mloginHost() {
    return this.appConfig.mloginHost;
  }

  get profileUrl() {
    return this.appConfig.mloginHost + '/ui/portal/mobilesdk/profile';
  }

  get paymentUrl() {
    return this.appConfig.mloginHost + '/ui/portal/mobilesdk/paymentmethods';
  }
}
