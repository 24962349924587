import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import uuidv4 from 'uuid/v4';

@Injectable({
  providedIn: 'root',
})
export class CorrelationIdInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const uuid = uuidv4();
    req = req.clone({ setHeaders: { 'X-Correlation-Id': uuid } });
    return next.handle(req);
  }
}
