<div>
  <canvas
    id="chart"
    baseChart
    [datasets]="chartDataSets"
    [options]="chartOptions"
    [type]="'bar'"
    [legend]="false"
    [labels]="chartLabels"
    [plugins]="chartPlugins"
  >
  </canvas>
</div>
