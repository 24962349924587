export class ChartConstants {
  static readonly CHART_FONT_FAMILY = 'Roboto Flex';
  static readonly CHART_FONT_SIZE = 12;

  static readonly GRID_LINE_COLOR = '#DDDDDD';
  static readonly GRID_LINE_STROKE_THICK = 1.5;
  static readonly GRID_LINE_STROKE_THIN = 0.5;
  static readonly TICKS_PADDING = 8;

  static readonly TRANSPARENT = 'rgba(255,255,255,0)';
  static readonly BLACK = '#000000';
  static readonly SWM_BLUE = '#27569a';
  static readonly SWM_BLUE_TRANSPARENT = 'rgba(39, 86, 154, 0.5)';
  static readonly SWM_BLUE_SONNENBAUSTEINE = '#009ee0';
  static readonly SWM_GREEN = 'rgba(117, 173, 64, 1)';
  static readonly SWM_GREEN_TRANSPARENT = 'rgba(117, 173, 64, 0.5)';
  static readonly SWM_ORANGE = '#eb6909';
  static readonly SWM_ORANGE_TRANSPARENT = 'rgba(235, 105, 9, 0.5)';
  static readonly SWM_YELLOW = '#faba00';
  static readonly SWM_YELLOW_TRANSPARENT = 'rgba(250, 186, 0, 0.5)';
  static readonly SWM_GREEN_PARTNERKRAFT = ChartConstants.SWM_GREEN;
  static readonly SWM_DARK_GREEN_PARTNERKRAFT = '#00925b';
  static readonly SWM_ORANGE_PARTNERKRAFT = ChartConstants.SWM_ORANGE;
  static readonly SWM_GRAY = 'rgba(103, 103, 103, 1)';
  static readonly SWM_GRAY_TRANSPARENT = 'rgba(103, 103, 103, 0.5)';
  static readonly SWM_GRAY_PARTNERKRAFT = ChartConstants.SWM_GRAY;
  static readonly SWM_GRAY_PARTNERKRAFT_TRANSPARENT = 'rgba(84, 106, 117, 0.1)';
  static readonly SWM_BLUE_PARTNERKRAFT = 'rgba(0, 158, 224, 0.3)';
  static readonly SWM_BLUE_PARTNERKRAFT_UC3_FORECASTS = [
    '#009EE0',
    '#27569A',
    '#009AB1',
  ];
}
