import { AbstractTimeseriesChartNavigationHelper } from './abstract-timeseries-chart-navigation-helper';
import moment from 'moment';
import { DateRange } from '../../date/date-data';
import { ChartPaginationEvent } from '../chart-pagination/chart-pgination-data';

export class MonthChartNavigationHelper extends AbstractTimeseriesChartNavigationHelper {
  getRefreshDateRange(): DateRange {
    const from = this.currentFromDate.clone().subtract(3, 'M');
    const to = this.currentFromDate.clone().add(15, 'M');
    return new DateRange(from, to);
  }

  initFromDate() {
    return moment()
      .startOf('M')
      .subtract(this.numberOfRecords - 1, 'M');
  }

  get dateGranualityUnit(): string {
    return 'M';
  }

  isNextEnabled(): boolean {
    const now = moment().startOf('M');
    const nextDisplayTo = this.currentFromDate
      .clone()
      .add(this.numberOfRecords - 1, 'M');
    return nextDisplayTo.isBefore(now);
  }

  isPreviousEnabled(): boolean {
    return true;
  }

  handleNextEvent(event: ChartPaginationEvent): DateRange {
    this.currentFromDate.add(1, 'M');
    const displayTo = this.currentFromDate
      .clone()
      .add(this.numberOfRecords - 1, 'M');
    const currentEnd = this.data.daten[this.data.daten.length - 1].t;
    if (displayTo.isAfter(currentEnd)) {
      return new DateRange(
        currentEnd.clone().add(2, 'M'),
        currentEnd.clone().add(this.numberOfRecords + 6, 'M')
      );
    }
    return null;
  }

  handlePreviousEvent(event: ChartPaginationEvent): DateRange {
    this.currentFromDate.subtract(1, 'M');
    const currentStart = this.data.daten[0].t;
    if (this.currentFromDate.isBefore(currentStart)) {
      return new DateRange(
        currentStart.clone().subtract(this.numberOfRecords + 5, 'M'),
        currentStart.clone().subtract(0, 'M')
      );
    }
    return null;
  }
}
