<ng-container>
  <mat-checkbox [(ngModel)]="hasExtraAddress" (change)="emitHasExtraAddressChange()" color="primary">
    <b [ngClass]="{'user-account': accountStyle}">Abweichend von Kontaktadresse</b>
  </mat-checkbox>
  <form *ngIf="hasExtraAddress" [formGroup]="formGroup">
    <div *ngIf="isCommercial; else privateCustomer">
      <mat-form-field>
        <mat-label [ngClass]="{'user-account': accountStyle}">Firma</mat-label>
        <input matInput formControlName="addressCompanyName" readonly>
      </mat-form-field>
      <mat-form-field>
        <mat-label [ngClass]="{'user-account': accountStyle}">Vorname (optional)</mat-label>
        <input matInput formControlName="givenName" [maxlength]="accountPropertiesMaxLength">
      </mat-form-field>
      <mat-form-field>
        <mat-label [ngClass]="{'user-account': accountStyle}">Nachname (optional)</mat-label>
        <input matInput formControlName="familyName" [maxlength]="accountPropertiesMaxLength">
      </mat-form-field>
    </div>
    <ng-template #privateCustomer>
      <mat-form-field>
        <mat-label [ngClass]="{'user-account': accountStyle}">Vorname</mat-label>
        <input matInput formControlName="givenName" [maxlength]="accountPropertiesMaxLength">
      </mat-form-field>
      <mat-form-field>
        <mat-label [ngClass]="{'user-account': accountStyle}">Nachname</mat-label>
        <input matInput formControlName="familyName" [maxlength]="accountPropertiesMaxLength">
      </mat-form-field>
    </ng-template>
    <mat-form-field>
      <mat-label [ngClass]="{'user-account': accountStyle}">Straße</mat-label>
      <input matInput formControlName="street" [maxlength]="accountPropertiesMaxLength">
    </mat-form-field>
    <mat-form-field>
      <mat-label [ngClass]="{'user-account': accountStyle}">Hausnummer</mat-label>
      <input matInput formControlName="houseNr" [maxlength]="accountPropertiesMaxLength">
    </mat-form-field>
    <mat-form-field>
      <mat-label [ngClass]="{'user-account': accountStyle}">Adresszusatz (optional)</mat-label>
      <input matInput formControlName="addressSupplement" [maxlength]="accountPropertiesMaxLength">
    </mat-form-field>
    <mat-form-field>
      <mat-label [ngClass]="{'user-account': accountStyle}">PLZ</mat-label>
      <input matInput formControlName="zipCode" (keydown)="validate($event)" maxlength="5">
    </mat-form-field>
    <mat-form-field>
      <mat-label [ngClass]="{'user-account': accountStyle}">Stadt</mat-label>
      <input matInput formControlName="city" [maxlength]="accountPropertiesMaxLength">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Land</mat-label>
      <input matInput [value]="'Deutschland'" disabled>
    </mat-form-field>
    <small>
      Eine Lieferung von Ladekarten an Packstationen sowie an Adressen außerhalb Deutschlands ist leider nicht
      möglich.
    </small>
  </form>
</ng-container>
