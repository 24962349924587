import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/user/user.service';
import { CustomerDataService } from '../../ladeloesung/shared/customer-data.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
})
export class AccountHeaderComponent implements OnInit {
  constructor(
    public customerDataService: CustomerDataService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.customerDataService.read().subscribe();
  }
}
