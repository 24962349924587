import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '../form/utils';
import {
  isChangedInput,
  SimpleChanges,
} from '../../core/utils/on-changes.utils';
import { PaymentOptionConfig } from './payment-option/payment-option.component';
import {
  isAmericanExpressMLoginPaymentType,
  MLoginPaymentDetails,
} from '../../core/user/m-login-payment-details.service';
import { UserService } from '../../core/user/user.service';
import {
  hasBillingAddress,
  isMLoginPaymentType,
  PaymentDetails,
  PaymentType,
} from '../../ladeloesung/shared/customer-data.service';
import { isEqual, omit } from 'lodash';
import { AddressOverviewData } from '../../card-ordering/card-ordering-data';
import { Schema } from 'type-fest';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerData } from '../../core/user/customer-data-storage.service';

interface PaymentOptionConfigs {
  [key: string]: PaymentOptionConfig;

  mlogin: PaymentOptionConfig;
  invoice: PaymentOptionConfig;
}

type BillingAddressFormModel = Schema<
  Omit<AddressOverviewData, 'salutation'>,
  UntypedFormControl
>;

interface PaymentDetailsFormGroup extends UntypedFormGroup {
  value: PaymentDetails;
  controls: {
    paymentType: UntypedFormControl;
    billingAddress?: UntypedFormGroup & {
      controls: BillingAddressFormModel;
    };
  };

  getRawValue(): PaymentDetails;
}

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnChanges {
  @Input() public paymentDetails: PaymentDetails;
  @Input() public mLoginPaymentDetails: MLoginPaymentDetails;
  @Input() public isCommercial: boolean;
  @Input() public customerData: CustomerData;
  @Input() public emitPaymentDetailsButtonLabel = 'Speichern';
  /**
   * This parameter defines whether the button should be clickable
   * even if nothing has changed on the payment method configuration.
   */
  @Input()
  public isEmitPaymentDetailsButtonDisabledForEqualPaymentMethods = true;
  @Output() public paymentDetailsChange = new EventEmitter<PaymentDetails>();
  @Output() public mLoginPaymentDetailsRedirect = new EventEmitter<string>();

  public form: PaymentDetailsFormGroup = this.createForm();
  private billingAddressFormGroup = this.form.controls.billingAddress;
  protected billingAddressFormModel = this.billingAddressFormGroup.controls;

  protected customerUnknown: boolean;
  readonly customerUnknownDescription = {
    errorDescription:
      'Speichern des Zahlungsmittels nicht möglich. Bitte versuchen Sie es in wenigen Minuten erneut.',
  };

  protected hasExtraAddress = false;
  public paymentOptions: PaymentOptionConfigs = {
    mlogin: {
      type: PaymentType.MLogin,
      mLoginPaymentDetails: null,
    },
    invoice: {
      type: PaymentType.Invoice,
    },
  };

  public constructor(
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-info-outline.svg'
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges<PaymentDetailsComponent>): void {
    const { mLoginPaymentDetails, paymentDetails, isCommercial, customerData } =
      changes;

    if (isChangedInput(mLoginPaymentDetails)) {
      this.paymentOptions.mlogin = {
        ...this.paymentOptions.mlogin,
        mLoginPaymentDetails: mLoginPaymentDetails.currentValue,
      };
      this.form.controls.paymentType.updateValueAndValidity();
    }

    if (isChangedInput(customerData)) {
      this.customerUnknown =
        !!this.customerData && !this.customerData.customerNumber;
    }

    if (isChangedInput(paymentDetails)) {
      const currentPaymentDetails = paymentDetails.currentValue;

      let propertiesToOmit: string[];
      if (currentPaymentDetails?.billingAddress) {
        propertiesToOmit = ['billingAddress.salutation'];
      } else {
        propertiesToOmit = ['billingAddress'];
      }

      this.paymentDetails = omit(currentPaymentDetails, propertiesToOmit);
      this.form.patchValue(this.paymentDetails);
      this.billingAddressFormGroup.patchValue(
        this.paymentDetails.billingAddress
      );
      this.hasExtraAddress =
        hasBillingAddress(this.paymentDetails) || this.hasExtraAddress;
      this.addOrRemoveBillingAddressForm();
      this.form.updateValueAndValidity();
    }

    if (isChangedInput(isCommercial)) {
      if (!this.isCommercial) {
        if (this.mLoginPaymentDetails) {
          this.form.patchValue({ paymentType: PaymentType.MLogin });
        } else {
          this.form.patchValue({ paymentType: null });
        }
      }
    }
  }

  canSaveModifications(): boolean {
    return !this.isEmitButtonDisabled();
  }

  get currentPaymentDetails(): PaymentDetails {
    return this.form.getRawValue()
  }

  protected setPayment(paymentType: PaymentType): void {
    if (paymentType === PaymentType.MLogin && !this.mLoginPaymentDetails) {
      // redirect to m-login if user chose m-login as payment option,
      // but no payment details are available in m-login
      this.navigateToMLoginPaymentPage();
      return;
    } else {
      this.form.patchValue({
        paymentType,
      });
      this.addOrRemoveBillingAddressForm();
    }
  }

  protected navigateToMLoginPaymentPage() {
    if (this.mLoginPaymentDetailsRedirect?.observers.length > 0) {
      this.mLoginPaymentDetailsRedirect.emit(this.userService.paymentUrl);
    } else {
      this.userService.navigateToMLogin(this.userService.paymentUrl);
    }
  }

  protected emitPaymentDetails(): void {
    const arePaymentDetailsEmitable = !this.isEmitButtonDisabled();

    if (arePaymentDetailsEmitable) {
      this.paymentDetailsChange.emit(this.form.getRawValue());
      this.paymentDetails = this.form.getRawValue();
    }
  }

  protected isEmitButtonDisabled(): boolean {
    const isSamePaymentOptionSelected =
      !!this.isEmitPaymentDetailsButtonDisabledForEqualPaymentMethods &&
      isEqual(this.paymentDetails, this.form.getRawValue());
    return this.form.invalid || isSamePaymentOptionSelected;
  }

  protected isMLoginSelected(): boolean {
    return this.form.getRawValue().paymentType === PaymentType.MLogin;
  }

  protected isInvoiceSelected(): boolean {
    return this.form.getRawValue().paymentType === PaymentType.Invoice;
  }

  private addOrRemoveBillingAddressForm(): void {
    const selectedPaymentType = this.form.getRawValue().paymentType;
    const isInvoice = selectedPaymentType === PaymentType.Invoice;

    if (isInvoice && this.hasExtraAddress) {
      this.form.addControl('billingAddress', this.billingAddressFormGroup);
    } else {
      this.form.removeControl('billingAddress');
    }
  }

  public onHasExtraAddressChange(hasExtraAddress: boolean): void {
    this.hasExtraAddress = hasExtraAddress;
    this.addOrRemoveBillingAddressForm();
  }

  private isAmericanExpressFormValidation({
    value,
  }: AbstractControl): ValidationErrors | null {
    const isInvalid =
      isMLoginPaymentType(value) &&
      isAmericanExpressMLoginPaymentType(this.mLoginPaymentDetails);

    return isInvalid ? { isAmericanExpress: true } : null;
  }

  private isMissingMLoginPaymentConfigration({
    value,
  }: AbstractControl): ValidationErrors | null {
    const isInvalid =
      isMLoginPaymentType(value) && this.mLoginPaymentDetails == null;

    return isInvalid ? { missingMLoginPaymentConfiguration: true } : null;
  }

  private createForm(): PaymentDetailsFormGroup {
    return new UntypedFormGroup({
      paymentType: new UntypedFormControl(null, [
        Validators.required,
        this.isAmericanExpressFormValidation.bind(this),
        this.isMissingMLoginPaymentConfigration.bind(this),
      ]),
      billingAddress: new UntypedFormGroup({
        companyName: new UntypedFormControl(null, [
          Validators.required,
          CustomValidators.semicolonNotIncluded,
        ]),
        street: new UntypedFormControl(null, [
          Validators.required,
          CustomValidators.semicolonNotIncluded,
        ]),
        houseNr: new UntypedFormControl(null, [
          Validators.required,
          CustomValidators.semicolonNotIncluded,
        ]),
        zipCode: new UntypedFormControl(null, [
          Validators.required,
          CustomValidators.germanZipCode,
        ]),
        city: new UntypedFormControl(null, [
          Validators.required,
          CustomValidators.semicolonNotIncluded,
        ]),
        familyName: new UntypedFormControl(null, [
          CustomValidators.semicolonNotIncluded,
        ]),
        givenName: new UntypedFormControl(null, [
          CustomValidators.semicolonNotIncluded,
        ]),
        addressSupplement: new UntypedFormControl(null, [
          CustomValidators.semicolonNotIncluded,
        ]),
        country: new UntypedFormControl({ value: 'Deutschland', disabled: true }),
      } as BillingAddressFormModel),
    }) as PaymentDetailsFormGroup;
  }
}
