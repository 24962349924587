import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class MLoginPaymentDetailsService {
  private PAYMENT_KEY = 'MLoginPaymentDetails';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): MLoginPaymentDetails {
    return this.storage.get(this.PAYMENT_KEY);
  }

  public set(paymentDetails: MLoginPaymentDetails) {
    this.storage.set(this.PAYMENT_KEY, paymentDetails);
  }

  public remove() {
    this.storage.remove(this.PAYMENT_KEY);
  }
}

export class MLoginPaymentDetails {
  type: string;
  lastDigits: string;
}

export function isSepaMLoginPaymentType(
  paymentDetails?: MLoginPaymentDetails
): boolean {
  return isMLoginPaymentType('sepa', paymentDetails);
}

export function isAmericanExpressMLoginPaymentType(
  paymentDetails?: MLoginPaymentDetails
): boolean {
  return isMLoginPaymentType('amex', paymentDetails);
}

function isMLoginPaymentType(
  name: string,
  paymentDetails?: MLoginPaymentDetails
): boolean {
  return paymentDetails != null && paymentDetails.type === name;
}
