import { Injectable } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints as MatBreakpoints,
} from '@angular/cdk/layout';
import { NGXLogger } from 'ngx-logger';

export const Breakpoints = {
  XSmall: MatBreakpoints.XSmall,
  Small: '(min-width: 600px) and (max-width: 839px)',
  Medium: '(min-width: 840px) and (max-width: 1279px)',
  Large: '(min-width: 1280px)',
};

export const MOBILE_DEVICE = 'mobile';
export const DESKTOP_DEVICE = 'desktop';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private $device: string;

  private $size: string;

  private $standalone = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private logger: NGXLogger
  ) {
    const prop = 'standalone';
    if (window.navigator[prop]) {
      this.logger.debug('navigator is ins standalone mode');
      this.$standalone = true;
    } else if (
      window.matchMedia('all and (display-mode: standalone)').matches
    ) {
      this.logger.debug('display mode matches standalone');
      this.$standalone = true;
    }

    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) {
          this.$device = MOBILE_DEVICE;
          if (breakpointObserver.isMatched(Breakpoints.XSmall)) {
            this.$size = 'xs';
          } else {
            this.$size = 'sm';
          }
        }
      });
    breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large])
      .subscribe((result) => {
        if (result.matches) {
          this.$device = DESKTOP_DEVICE;
          if (breakpointObserver.isMatched(Breakpoints.Medium)) {
            this.$size = 'md';
          } else {
            this.$size = 'lg';
          }
        }
      });
  }

  get device(): string {
    return this.$device;
  }

  get size(): string {
    return this.$size;
  }

  get isStandalone(): boolean {
    return this.$standalone;
  }

  get isMobile(): boolean {
    return this.$device === MOBILE_DEVICE;
  }

  get isDesktop(): boolean {
    return this.$device === DESKTOP_DEVICE;
  }
}
