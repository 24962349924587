import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  BusinessError,
  InternalError,
  NetworkError,
} from '../error-handler/error-handler';
import { UserService } from '../user/user.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private user: UserService, private logger: NGXLogger) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        this.logger.debug('Caught error', response);
        if (response.status === 401) {
          this.user.clearCredentials(location.pathname);
          return NEVER;
        } else {
          let error: Error;

          if (response.error instanceof ErrorEvent) {
            error = new InternalError(response.error.message, response.error);
          } else if (response.error && response.error.message) {
            error = new BusinessError(response.status, response.error);
          } else {
            error = new NetworkError(response.status, response.message);
          }
          // return an observable with a user-facing errors message
          return throwError(error);
        }
      })
    );
  }
}
