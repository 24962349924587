import { SimpleChange as NgSimpleChange } from '@angular/core';
import { isEqual } from 'lodash';

type MarkFunctionProperties<Component> = {
  // We need to find properties that are actually Functions
  // tslint:disable-next-line:ban-types
  [Key in keyof Component]: Component[Key] extends Function ? never : Key;
};
type ExcludeFunctionPropertyNames<T> = MarkFunctionProperties<T>[keyof T];
type ExcludeFunctions<T> = Pick<T, ExcludeFunctionPropertyNames<T>>;

interface SimpleChange<T> extends NgSimpleChange {
  previousValue: T;
  currentValue: T;
}

export type SimpleChanges<Component, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: SimpleChange<Props[Key]>;
};

export function isChangedInput<T>(change: SimpleChange<T>): boolean {
  return change && !isEqual(change.currentValue, change.previousValue);
}
