import { Component, Input, OnChanges } from '@angular/core';
import { isEmpty, isObject } from 'lodash';
import { Moment } from 'moment';

export interface SwiperItem {
  icon: string;
  title: string;
  date?: Moment;
  value?: number;
  unit: string;
}

export interface SwiperConfig {
  iconName: string;
  title: string;
  unit: string;
  propertyName: string;
}

export interface TimestampedValue {
  date: Moment;
  value: number;
}

export interface IconConfig {
  iconName: string;
  iconSvg: string;
}

// used to ensure that a property exists in the <T> class during the compilation (not in runtime).
export const ensurePropertyExists = <T>(name: keyof T) => name;

@Component({
  selector: 'app-info-swiper',
  templateUrl: './info-swiper.component.html',
  styleUrls: ['./info-swiper.component.scss'],
})
export class InfoSwiperComponent implements OnChanges {

  @Input() swiperData: Object;
  @Input() swiperConfigs: SwiperConfig[];
  swiperItems: SwiperItem[];

  constructor() {}

  ngOnChanges() {
    this.swiperItems = this.createSwiperItems();
  }

  isDateVisible(item: SwiperItem): boolean {
    return item && !isEmpty(item.date);
  }

  private createSwiperItems(): SwiperItem[] | undefined {
    if (!this.swiperData) {
      return undefined;
    }
    return this.swiperConfigs.map((swiperConfig: SwiperConfig) => {
      return InfoSwiperComponent.createSwiperItem(
        this.swiperData[swiperConfig.propertyName],
        swiperConfig
      );
    });
  }

  private static createSwiperItem(
    value: number | TimestampedValue,
    swiperConfig: SwiperConfig
  ): SwiperItem {
    const swiperItem: SwiperItem = {
      icon: swiperConfig.iconName,
      title: swiperConfig.title,
      date: null,
      value: null,
      unit: swiperConfig.unit,
    };
    if (isObject(value)) {
      swiperItem.value = (value as TimestampedValue).value;
      swiperItem.date = (value as TimestampedValue).date;
    } else {
      swiperItem.value = value as number;
    }
    return swiperItem;
  }
}
