import moment, { Moment } from 'moment';
import { assign } from 'lodash';

export enum WallboxStatus {
  STOERUNGSFREI_M_AUTO,
  STOERUNGSFREI_O_AUTO,
  STOERUNG_M_AUTO,
  STOERUNG_O_AUTO,
  OFFLINE,
}

export interface ChargingProcessesFilterMetaData {
  connectorIds: string[];
  accessIds: string[];
}

export class Location {
  stadt: string;
  hausnummer: string;
  id: string;
  postleitzahl: string;
  strasse: string;
  longitude?: number;
  latitude?: number;
}

export class AutocompleteLocationSuggestion {
  addressLineOne: string;
  addressLineTwo: string;
  lat: number;
  lon: number;
  formatted: string;

  constructor(
    lat: number,
    lon: number,
    address_line1: string,
    address_line2: string,
    formatted: string
  ) {
    this.lat = lat;
    this.lon = lon;
    this.addressLineOne = address_line1;
    this.addressLineTwo = address_line2;
    this.formatted = formatted;
  }
}

export class Ladestatus {
  id: string;
  status: WallboxStatus;
  geladeneEnergiemenge: number;
  ladebeginn: moment.Moment;
  ladeende: moment.Moment;
  zaehlerstandLadebeginn: number;
  aktuelleLadeleistung: number;
  stoerungText: string;
  ort: Location;
  powertype: string;
  distance: number;

  constructor(obj?: any) {
    assign(this, obj);
    this.ladebeginn = moment(obj.ladebeginn);
    this.ladeende = moment(obj.ladeende);
    this.status = WallboxStatus[obj.status] as unknown as WallboxStatus;
  }
}

export interface ChargingStationStatusInfo {
  status: string;
  message: string;
}

export interface ChargingCosts {
  acPricePerKwh: number;
  dcPricePerKwh: number;
}

export interface Operator {
  name: string;
  website: string;
  logoUrl: string;
}

export class ChargingStation {
  isPublic: boolean;
  isFavorite: boolean;
  address: Location;
  distance: number;
  powerType: string;
  connectorType?: string;
  connectorStatusInfos: ConnectorStatusInfo[];
  statusInfo?: ChargingStationStatusInfo;
  chargingCostsWithSwmCard?: ChargingCosts;
  operator?: Operator;

  constructor(obj?: any) {
    assign(this, obj);
    this.distance = this.roundDistance(obj.distance);
  }

  roundDistance?(distance: number): number | undefined {
    if (distance == null) {
      return undefined;
    }
    return parseFloat((distance / 1000).toFixed(1));
  }
}

export interface ConnectorStatusInfo {
  id: string;
  status: string;
  connectorType: string;
  powerType: string;
  maxPower?: number;
  errorType?: string;
}

export enum ChargingSessionType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export abstract class ChargingSession {
  type: ChargingSessionType;
  id: string;
  address: string;
  cardNumber: string;
  connectorId: string;
  powerType: string;
  sessionStart: moment.Moment;
  sessionEnd: moment.Moment;
  cardName?: string;
  cardDescription?: string;
}

export class PrivateChargingSession extends ChargingSession {
  malfunction?: Malfunction;
  meterStart: number;
  meterEnd: number;
  meterPresent: number;
  signatur: string;
  currentChargingPower: string;
}

export class PublicChargingSession extends ChargingSession {
  totalEnergy: number;
  operator: string;
  country: string;
  netCostOfTotalEnergy: number
}

export class ChargingSessionHistoryDetails {
  accessIds: string[];
  connectorIds: string[];
  privateChargingSessions: PrivateChargingSession[];
  publicChargingSessions: PublicChargingSession[];
}

export class Malfunction {
  errorType?: string;
  timestamp?: moment.Moment;
}

export interface PublicChargingCurrentTariffInfo {
  old?: {
    validUntil: Moment;
    acPricePerKwh: number;
    dcPricePerKwh: number;
    basicFees: number;
    oneTimeCostsPerCard: number;
    additionalCardsCostsInMonth: number;
  };
  new: {
    validFrom: Moment;
    acPricePerKwh: number;
    dcPricePerKwh: number;
    basicFees: number;
    oneTimeCostsPerCard: number;
    additionalCardsCostsInMonth: number;
  };
}
