import { Component, Input, OnInit } from '@angular/core';

import { get, replace } from 'lodash';

export interface StatusConfiguration {
  imageUrl?: string;
  cssClass?: string;
}

export interface StatusConfigurations {
  [status: string]: StatusConfiguration;
}

export interface ImageText {
  label?: String;
  value?: String;
}

@Component({
  selector: 'app-status-image',
  templateUrl: './status-image.component.html',
  styleUrls: ['./status-image.component.scss'],
})
export class StatusImageComponent implements OnInit {
  @Input()
  status: string;

  @Input()
  description: string;

  @Input()
  showDescription: boolean = true;

  @Input()
  settings: StatusConfigurations;

  @Input()
  innerDescription: ImageText;

  constructor() {}

  ngOnInit() {}

  get style() {
    const imgUrl = get(this.settings, this.status + '.imageUrl', '');
    if (!imgUrl) {
      return {};
    }
    const backgroundUrl = 'url(' + imgUrl + ')';
    return {
      'background-image': backgroundUrl,
    };
  }

  get cssClasses(): string {
    let classes = 'app-status-' + replace(this.status, ' ', '_');
    const cssClass = get(this.settings, this.status + '.cssClass');
    if (cssClass) {
      classes += ' ' + cssClass;
    }
    return classes;
  }
}
