<app-account-header *ngIf="screen.isMobile"></app-account-header>

<mat-nav-list class="app-nav-list">
  <div *ngIf="isLadeloesung">
    <div class="button-group-title mat-body-2">
      Ladekarten und Verträge
    </div>
    <div class="button-group">
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/card-activation"
      >
        <span matListItemTitle>Meine Ladekarten</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/contracts"
      >
        <span>Meine Verträge</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider></mat-divider>
    </div>
    <div class="button-group-title mat-body-2">
      Rechnungen
    </div>
    <div class="button-group">
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/invoices"
      >
        <span matListItemTitle>Meine Rechnungen</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider></mat-divider>
    </div>
    <div class="button-group-title mat-body-2">
      Profil
    </div>
    <div class="button-group">
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/user-data"
      >
        <span matListItemTitle>Meine Daten</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/payment-methods"
      >
        <span matListItemTitle>Meine Zahlungsmittel</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="button-group support-logout">
    <div *ngIf="isLadeloesung">
      <mat-divider class="mobile-only"></mat-divider>
      <a mat-list-item
         class="app-account-item"
         [attr.target]="screen.isStandalone ? '_blank' : null"
         routerLink="/ladeloesung/account/help-support"
      >
        <span matListItemTitle>Hilfe und Support</span>
        <span matListItemMeta>
          <mat-icon class="mobile-only">keyboard_arrow_right</mat-icon>
        </span>
      </a>
      <mat-divider class="mobile-only"></mat-divider>
    </div>
    <div class="centered">
      <a mat-list-item (click)="user.logout()" class="logout">
        <span class="logout">Abmelden</span>
      </a>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="centered links">
    <span>
      <a href="https://www.swm.de/impressum">Impressum</a>
      <a href="https://www.swm.de/datenschutz/swm-versorgungs-gmbh">Datenschutz</a>
    </span>
  </div>
</mat-nav-list>
