<app-modal-form
  title="Filter"
  [submitAction]="submitAction"
  [resetAction]="resetAction"
  [formGroup]="filterFormGroup"
>
  <div class="app-filter-form">
    <mat-form-field floatLabel="always" class="app-filter-dropdown">
      <mat-label>Zeitraum auswählen</mat-label>
      <select matNativeControl formControlName="zeitraum">
        <option [ngValue]="ZeitraumEnum.ALL">Letzte 2 Wochen</option>
        <option [ngValue]="ZeitraumEnum.DAY">Heute</option>
        <option [ngValue]="ZeitraumEnum.WEEK">Diese Woche</option>
        <option [ngValue]="ZeitraumEnum.MONTH">Dieser Monat</option>
        <option [ngValue]="ZeitraumEnum.USER_DEFINED">Benutzerdefiniert</option>
      </select>
    </mat-form-field>

    <div class="app-filter-datepicker-wrapper">
      <mat-form-field class="app-filter-datepicker">
        <input
          matInput
          [matDatepicker]="dpfrom"
          placeholder="Von"
          formControlName="dateFrom"
        >
        <mat-datepicker-toggle matSuffix [for]="dpfrom"></mat-datepicker-toggle>
        <mat-datepicker #dpfrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="app-filter-datepicker">
        <input
          matInput
          [matDatepicker]="dpto"
          placeholder="Bis"
          formControlName="dateTo"
        >
        <mat-datepicker-toggle matSuffix [for]="dpto"></mat-datepicker-toggle>
        <mat-datepicker #dpto></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</app-modal-form>
