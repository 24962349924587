export enum ChartPaginationEventType {
  PREVIOUS,
  NEXT,
}

export class ChartPaginationEvent {
  constructor(
    private _type: ChartPaginationEventType,
    private _eventData: any
  ) {}

  get type(): ChartPaginationEventType {
    return this._type;
  }

  get data(): any {
    return this._eventData;
  }
}
