import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-with-content-page',
  templateUrl: './icon-with-content-page.component.html',
  styleUrls: ['./icon-with-content-page.component.scss'],
})
export class IconWithContentPageComponent implements OnInit {
  @Input()
  svgIcon: string;

  @Input()
  icon: string;

  constructor() {}

  ngOnInit(): void {}
}
