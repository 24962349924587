import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() status = 'error';
  @Input() title = 'Meldung';
  @Input() content: string;
  @Input() label = 'OK';
  @Input() showStatusIcon: boolean = true;

  constructor() {}
}
