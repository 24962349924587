import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-title-with-close-button-toolbar-row',
  templateUrl: './title-with-close-button-toolbar-row.component.html',
  styleUrls: ['./title-with-close-button-toolbar-row.component.scss'],
})
export class TitleWithCloseButtonToolbarRowComponent implements OnInit {
  @Input()
  title: string;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }
}
