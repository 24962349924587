import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SemicolonNotIncludedErrors, ZipCodeErrors } from '../utils';

export type ErrorName =
  | keyof ZipCodeErrors
  | keyof SemicolonNotIncludedErrors
  | 'minlength';

interface ErrorMapping {
  errorName: ErrorName;
  message: string;
}

interface ErrorMessageFactoryFor {
  [key: ErrorName]: (replacements?: unknown) => string;

  minlength(value: { requiredLength: number; actualLength: number }): string;
}

export const errorMessageFactoryFor: ErrorMessageFactoryFor = {
  includesSemicolon: () => 'Ihre Eingabe darf keine Semicolons enthalten.',
  invalidZipCode: () => 'Bitte geben Sie eine gültige Postleitzahl ein.',
  minlength: ({ requiredLength }) =>
    `Dieses Feld muss mit mindestens ${requiredLength} Zeichen befüllt werden.`,
};

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements OnInit {
  @Input() public control: AbstractControl;

  public errorMappings: ErrorMapping[] = [];

  public ngOnInit(): void {
    this.errorMappings = this.getErrorMessages(this.control.errors);
    this.control.statusChanges.subscribe((value) => {
      switch (value) {
        case 'VALID':
          this.errorMappings = [];
          break;
        case 'INVALID':
          this.errorMappings = this.getErrorMessages(this.control.errors);
          break;
      }
    });
  }

  private getErrorMessages(errors: ValidationErrors): ErrorMapping[] {
    if (errors) {
      return Object.entries(errors).map(this.toErrorMapping);
    } else {
      return [];
    }
  }

  private toErrorMapping([errorName, error]: [ErrorName, any]): ErrorMapping {
    const hasErrorMessageFactoryFor = (name: ErrorName) =>
      !!errorMessageFactoryFor[name];

    if (hasErrorMessageFactoryFor(errorName)) {
      return {
        errorName,
        message: errorMessageFactoryFor[errorName](error),
      };
    } else {
      return null;
    }
  }
}
