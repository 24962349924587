import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from 'lodash';
import { DecimalPipe } from '@angular/common';
import { isNullOrUndefined, isNumber } from 'util';

const decimalPipe = new DecimalPipe('DE');
/*
 * Usage:
 *   value | appendUnit:{unit}[:precision[:{defaultValue}]]
 * Examples:
 *   {{ 2000 | appendUnit:'kW'}}
 *   will display: 2000 kW

 *   {{ 200.578 | appendUnit:'kW':1}}
 *   will display: 200,6 kW
 *
 *  {{ (undefined, NaN, null)  | appendUnit:'kW'}}
 *   will display: -
 */
@Pipe({
  name: 'appendUnit',
})
export class UnitAppenderPipe implements PipeTransform {
  /**
   *
   * @param value the value to be transformed.
   * @param unit the unit to be appended.
   * @param precision the number of digits to appear after the decimal point.
   * @param defaultValue will be used if the given <code>value</code> is null, undefined or NaN.
   */
  transform(
    value: number,
    unit: String = '',
    precision: number = 1,
    defaultValue: string = '-'
  ): String {
    let isNotNumber =
      isNullOrUndefined(value) || !isNumber(value) || isNaN(value);
    let valueAsNumber: number = toNumber(value);
    const digitsInfo = `1.${precision}-${precision}`;
    let valueAsString = `${decimalPipe.transform(
      valueAsNumber,
      digitsInfo
    )} ${unit}`;
    return isNotNumber && defaultValue ? defaultValue : valueAsString;
  }
}
