import {Component} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'maintenance-info',
  templateUrl: './maintenance-info.component.html',
  styleUrls: ['./maintenance-info.component.scss'],
})
export class MaintenanceInfoComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-info-outline.svg'
      )
    );
  }
}
