"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var pad_string_1 = require("./pad-string");
function encode(input, encoding) {
  if (encoding === void 0) {
    encoding = "utf8";
  }
  if (Buffer.isBuffer(input)) {
    return fromBase64(input.toString("base64"));
  }
  return fromBase64(Buffer.from(input, encoding).toString("base64"));
}
;
function decode(base64url, encoding) {
  if (encoding === void 0) {
    encoding = "utf8";
  }
  return Buffer.from(toBase64(base64url), "base64").toString(encoding);
}
function toBase64(base64url) {
  base64url = base64url.toString();
  return pad_string_1.default(base64url).replace(/\-/g, "+").replace(/_/g, "/");
}
function fromBase64(base64) {
  return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}
function toBuffer(base64url) {
  return Buffer.from(toBase64(base64url), "base64");
}
var base64url = encode;
base64url.encode = encode;
base64url.decode = decode;
base64url.toBase64 = toBase64;
base64url.fromBase64 = fromBase64;
base64url.toBuffer = toBuffer;
exports.default = base64url;