import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ChartPaginationEvent,
  ChartPaginationEventType,
} from './chart-pgination-data';

@Component({
  selector: 'app-chart-pagination',
  templateUrl: './chart-pagination.component.html',
  styleUrls: ['./chart-pagination.component.scss'],
})
export class ChartPaginationComponent implements OnInit {
  @Input()
  previousDisabled: boolean = false;

  @Input()
  nextDisabled: boolean = false;

  @Input()
  text: string;

  @Output()
  controlEvents = new EventEmitter<ChartPaginationEvent>();

  constructor() {}

  ngOnInit() {}

  next() {
    this.controlEvents.emit(
      new ChartPaginationEvent(ChartPaginationEventType.NEXT, null)
    );
  }

  previous() {
    this.controlEvents.emit(
      new ChartPaginationEvent(ChartPaginationEventType.PREVIOUS, null)
    );
  }
}
