import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private subject = new Subject<boolean>();
  private counter = 0;

  constructor() {}

  start() {
    this.counter++;
    this.subject.next(this.active);
  }

  end() {
    this.counter--;
    this.subject.next(this.active);
  }

  getProgress(): Observable<boolean> {
    return this.subject;
  }

  private get active(): boolean {
    return this.counter > 0;
  }
}
