<ng-template #defaultNav>
  <nav>
    <a
      mat-icon-button
      [attr.target]="screen.isStandalone ? '_blank' : null"
      href="https://www.swm.de/privatkunden/info/impressum.html"
    >
      <span class="wrapper">
        <mat-icon>fingerprint</mat-icon>
        <div>Impressum</div>
      </span>
    </a>
    <a
      mat-icon-button
      [attr.target]="screen.isStandalone ? '_blank' : null"
      href="https://www.swm.de/privatkunden/info/datenschutz.html"
    >
      <span class="wrapper">
        <mat-icon>lock</mat-icon>
        <div>Datenschutz</div>
      </span>
    </a>
    <button mat-icon-button (click)="user.logout()">
      <mat-icon>exit_to_app</mat-icon>
      <div>Abmelden</div>
    </button>
  </nav>
</ng-template>

<ng-template
  #toolbarIconButton
  let-title="name"
  let-svgIcon="svgIcon"
  let-iconName="icon"
  let-actionName="actionName"
  let-ariaLabel="ariaLabel"
>
  <a
    class="app-toolbar"
    mat-icon-button
    [title]="title"
    type="button"
    (click)="executeAction(actionName, $event)"
  >
    <mat-icon [svgIcon]="svgIcon" [attr.aria-hidden]="ariaLabel ? false : true"
              [attr.aria-label]="ariaLabel ? ariaLabel : null">{{ iconName }}</mat-icon>
  </a>
</ng-template>

<div class="app-navigation">
  <mat-sidenav-container style="flex: 1">
    <mat-sidenav-content>
      <mat-toolbar
        color="primary"
        class="app-navigation-top"
        *ngIf="screen.device === 'desktop'"
      >
        <nav class="app-container">
          <ng-container *ngIf="isShowTopLeftToolbarIconButton('desktop')">
            <ng-container
              *ngTemplateOutlet="
                toolbarIconButton;
                context: pageConfiguration?.topLeftToolbarIconButton
              "
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="!pageConfiguration?.hideDesktopNavigationMenu">
            <button
              mat-flat-button
              color="primary"
              disableRipple="true"
              class="app-menu-button"
              *ngFor="let item of navItems"
              [routerLink]="[item.link]"
              [routerLinkActive]="'active'"
            >
              {{ item.name }}
            </button>
          </ng-container>
          <div
            class="flex-spacer"
            [ngClass]="{
              'flex-spacer-page-title': pageConfiguration?.showMobilePageTitle
            }"
          ></div>
          <ng-container *ngIf="pageConfiguration?.showMobilePageTitle">
            <div class="app-page-title">{{ title.pageTitle }}</div>
            <div class="flex-spacer-page-title"></div>
          </ng-container>
          <ng-container *ngIf="isShowTopRightToolbarIconButton('desktop')">
            <ng-container
              *ngTemplateOutlet="
                toolbarIconButton;
                context: pageConfiguration?.topRightToolbarIconButton
              "
            ></ng-container>
          </ng-container>
          <button
            *ngIf="!pageConfiguration?.hideDesktopAccountMenu"
            class="app-user"
            mat-flat-button
            color="primary"
            disableRipple="true"
            [matMenuTriggerFor]="userMenu"
          >
            {{ user.email }}
            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #userMenu="matMenu">
            <app-account></app-account>
          </mat-menu>
        </nav>
        <mat-progress-bar
          *ngIf="inProgress"
          color="accent"
          mode="indeterminate"
          aria-label="Fortschritt beim Laden der Seite"
        >
        </mat-progress-bar>
      </mat-toolbar>

      <mat-toolbar
        color="primary"
        class="app-appbar-top"
        *ngIf="screen.device === 'mobile'"
      >
        <ng-container *ngIf="isShowTopLeftToolbarIconButton('mobile')">
          <ng-container
            *ngTemplateOutlet="
              toolbarIconButton;
              context: pageConfiguration?.topLeftToolbarIconButton
            "
          ></ng-container>
        </ng-container>
        <div class="app-page-title">{{ title.pageTitle }}</div>
        <div class="app-buttons">
          <ng-container *ngIf="isShowTopRightToolbarIconButton('mobile')">
            <ng-container
              *ngTemplateOutlet="
                toolbarIconButton;
                context: pageConfiguration?.topRightToolbarIconButton
              "
            ></ng-container>
          </ng-container>
        </div>

        <mat-progress-bar
          *ngIf="inProgress"
          color="accent"
          mode="indeterminate"
          aria-label="Fortschritt beim Laden der Seite"
        >
        </mat-progress-bar>
      </mat-toolbar>

      <div
        class="app-sidenav-content"
        [ngClass]="{ zero_padding_bottom: !showMobileBottomNavbar }"
      >
        <div
          class="app-container"
          [ngClass]="{
            'app-container-without-margins': pageConfiguration?.useAppFullWidth
          }"
        >
          <ng-content></ng-content>
        </div>
      </div>

      <mat-toolbar class="app-navigation-bottom" *ngIf="showMobileBottomNavbar">
        <nav *ngIf="navItems; else defaultNav">
          <button
            mat-icon-button
            routerLinkActive="active"
            *ngFor="let item of navItems"
            [routerLink]="[item.link]"
          >
            <span *ngIf="item.badge" class="nav-badge">{{ item.badge }}</span>
            <mat-icon [svgIcon]="item.svgIcon">{{ item.icon }}</mat-icon>
            <div>{{ item.name }}</div>
          </button>
          <button
            mat-icon-button
            routerLinkActive="active"
            [routerLink]="'account'"
          >
            <mat-icon>person</mat-icon>
            <div>Konto</div>
          </button>
        </nav>
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
