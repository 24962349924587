import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appStatusIndicator]',
})
export class StatusIndicatorDirective implements OnInit {
  @Input() color: string;
  @Input() size: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const nativeElement = this.el.nativeElement;
    const size = this.size ? this.size : '12px';

    nativeElement.classList.add('app-status-indicator');
    nativeElement.style.display = 'inline-block';
    nativeElement.style.width = size;
    nativeElement.style.height = size;
    nativeElement.style.borderRadius = size;

    if (this.color) {
      nativeElement.classList.add('app-color-' + this.color);
    }
  }
}
