import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DESKTOP_DEVICE,
  MOBILE_DEVICE,
  ScreenService,
} from '../../core/screen/screen.service';
import { UserService } from '../../core/user/user.service';
import { TitleService } from '../../core/title/title.service';
import { RefreshService } from '../../core/refresh/refresh.service';
import { ProgressService } from '../../core/progress/progress.service';
import { delay, filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AnchorIconButton, TopToolbarIconBotton } from './navigation-data';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AppPageComponent,
  getAppPageConfiguration,
  PageConfiguration,
} from '../component-base/app-page.component';
import { clone, defaults } from 'lodash';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input() navItems: NavItem[];
  @Input() showBottomNav?: boolean = null;
  opened = false;
  inProgress = false;
  subscription: Subscription;

  pageConfiguration: PageConfiguration;

  private currentPage: any;
  private pageConfigurationChangesSubscription: Subscription;

  private readonly defaultPageConfiguration: PageConfiguration = {
    showMobileBottomNavbar: true,
    showMobilePageTitle: false,
    hideDesktopAccountMenu: false,
    topLeftToolbarIconButton: null,
    topRightToolbarIconButton: null,
    useAppFullWidth: false,
    hideDesktopNavigationMenu: false,
  };

  constructor(
    public refresh: RefreshService,
    public screen: ScreenService,
    public user: UserService,
    public title: TitleService,
    private progress: ProgressService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((value) => {
        if (value && value.component) {
          let pageConfig = getAppPageConfiguration(value.component) || {};
          let newConfig = defaults(
            clone(pageConfig),
            this.defaultPageConfiguration
          );
          this.updatePageConfig(newConfig);
        }
      });
  }

  ngOnInit() {
    this.subscription = this.progress
      .getProgress()
      // Workaround für WebKit damit er genug Zeit für das Redraw hat
      .pipe(delay(50))
      .subscribe((active) => {
        this.inProgress = active;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updatePageConfig(pageConfig: PageConfiguration) {
    this.pageConfiguration = pageConfig;
  }

  onRouterOutletActivate(event: any) {
    if (this.pageConfigurationChangesSubscription) {
      this.pageConfigurationChangesSubscription.unsubscribe();
      this.pageConfigurationChangesSubscription = null;
    }
    this.currentPage = event;
    if (this.currentPage instanceof AppPageComponent) {
      this.pageConfigurationChangesSubscription =
        this.currentPage.pageConfigurationChanges.subscribe((pageConfig) => {
          let currentConfig =
            this.pageConfiguration || this.defaultPageConfiguration;
          this.updatePageConfig(defaults(pageConfig, clone(currentConfig)));
        });
    }
  }

  isShowTopLeftToolbarIconButton(device: string): boolean {
    return this.isShowToolbarIconButton(
      device,
      this.pageConfiguration?.topLeftToolbarIconButton
    );
  }

  isShowTopRightToolbarIconButton(device: string): boolean {
    return this.isShowToolbarIconButton(
      device,
      this.pageConfiguration?.topRightToolbarIconButton
    );
  }

  executeAction(actionName: string, event?: any) {
    if (this.currentPage && this.currentPage instanceof AppPageComponent) {
      this.currentPage.executeAction(actionName, event);
    }
  }

  private isShowToolbarIconButton(
    device: string,
    button?: TopToolbarIconBotton
  ): boolean {
    return (
      button &&
      ((device == DESKTOP_DEVICE && button.showOnDesktopDevice) ||
        (device == MOBILE_DEVICE && button.showOnMobileDevice))
    );
  }

  get showMobileBottomNavbar(): boolean {
    if (this.screen.device !== MOBILE_DEVICE) {
      return false;
    } else if (this.showBottomNav !== null) {
      return this.showBottomNav;
    }
    return this.pageConfiguration?.showMobileBottomNavbar;
  }
}

export type NavItem = AnchorIconButton;
