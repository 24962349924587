import moment, { Moment } from 'moment';
import { clone, isEmpty, isString, unionWith } from 'lodash';
import { DateRange } from '../date/date-data';

export class Timeseries {
  constructor(public name?: string, public daten?: TimeseriesRecord[]) {}

  /**
   * merges the records of <code>oldTimeseries</code> with the records of the <code>newTimeseries</code> .
   * If the <code>newTimeseries</code> and <code>oldTimeseries</code> contains a record with the same timestamp then
   * the record from the <code>newTimeseries</code> will be used.
   * @throws Error if the name of <code>oldTimeseries</code> and <code>newTimeseries</code> doesn't match.
   */
  public mergeWith(newTimeseries: Timeseries): Timeseries {
    const result = new Timeseries();
    result.name = this.name;
    let merged = null;
    if (!newTimeseries) {
      result.daten = this.daten;
      return result;
    }
    if (this.name !== newTimeseries.name) {
      throw Error(
        `Unable to merge timeseries. This name (${this.name}) does not match with the given timeseries name (${newTimeseries.name})`
      );
    }
    if (isEmpty(newTimeseries.daten)) {
      merged = this.daten;
    } else if (isEmpty(this.daten)) {
      merged = newTimeseries.daten;
    } else {
      merged = unionWith(
        newTimeseries.daten,
        this.daten,
        (otherRecord: TimeseriesRecord, thisRecord: TimeseriesRecord) => {
          const otherTimestamp = TimeseriesRecord.stringToMoment(otherRecord.t);
          return otherTimestamp.isSame(thisRecord.t);
        }
      );
    }

    result.daten = merged;
    return result;
  }

  public static replaceStringWithMoment(timeseries: Timeseries): void {
    if (timeseries.daten) {
      timeseries.daten.forEach((record) => {
        record.t = TimeseriesRecord.stringToMoment(record.t);
      });
    }
  }

  public static multiplyAllValues(
    timeseries: Timeseries,
    factor: number,
    cloneTimeseries: boolean = true
  ): Timeseries {
    if (!timeseries || !timeseries.daten) {
      return timeseries;
    }
    if (cloneTimeseries) {
      let newTs = new Timeseries(timeseries.name, []);
      timeseries.daten.forEach((record) => {
        newTs.daten.push(
          TimeseriesRecord.multiplyByValue(record, factor, true)
        );
      });
      return newTs;
    } else {
      timeseries.daten.forEach((record) => {
        TimeseriesRecord.multiplyByValue(record, factor, false);
      });
      return timeseries;
    }
  }

  public static filterRecordsInRange(
    timeseries: Timeseries,
    dateRange: DateRange
  ): Timeseries {
    if (!timeseries || isEmpty(timeseries.daten) || !dateRange) {
      return timeseries;
    }
    dateRange = dateRange.clone();
    let filteredTimeseries: Timeseries = new Timeseries(timeseries.name);
    filteredTimeseries.daten = timeseries.daten.filter((record) => {
      const timestamp = TimeseriesRecord.stringToMoment(record.t);
      return dateRange.includesDate(timestamp);
    });
    return filteredTimeseries;
  }

  public static sumAllValues(timeseries: Timeseries): number {
    if (!timeseries || isEmpty(timeseries.daten)) {
      return 0;
    }

    const result = timeseries.daten.reduce(
      (sum, record) => sum + (record.y === undefined ? 0 : record.y),
      0.0
    );
    return isNaN(result) ? 0 : result;
  }
}

export class TimeseriesRecord {
  t: moment.Moment | any;
  y: number;

  public static multiplyByValue(
    record: TimeseriesRecord,
    factor: number,
    cloneRecord: boolean = true
  ): TimeseriesRecord {
    if (!record || !record.y) {
      return cloneRecord ? clone(record) : record;
    }
    let newItem = record;
    if (cloneRecord) {
      newItem = clone(record);
    }
    newItem.y = newItem.y * factor;
    return newItem;
  }

  static stringToMoment(t: any): Moment {
    return isString(t) ? moment(t) : t;
  }
}
