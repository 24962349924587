import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Timeseries, TimeseriesRecord } from '../../timeseries/timeseries';
import { Moment, unitOfTime } from 'moment';
import { Subscription } from 'rxjs';
import { RefreshService } from '../../../core/refresh/refresh.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractTimeseriesService } from '../../timeseries/abstract-timeseries.service';
import {
  ChartPaginationEvent,
  ChartPaginationEventType,
} from '../chart-pagination/chart-pgination-data';
import { AbstractTimeseriesChartNavigationHelper } from './abstract-timeseries-chart-navigation-helper';
import { MonthChartNavigationHelper } from './month-chart-navigation-helper';
import { LabelProvider } from '../label-provider';

@Component({
  selector: 'app-timeseries-chart',
  templateUrl: './timeseries-chart.component.html',
  styleUrls: ['./timeseries-chart.component.scss'],
})
export class TimeseriesChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() backendService: AbstractTimeseriesService;
  @Input() chartStepWidths: number[];
  @Input() title: string;
  @Input() errorMessage: string;
  @Input() navigationHelper: AbstractTimeseriesChartNavigationHelper;
  @Input() timeLabelProvider: LabelProvider<Moment>;

  recordsToDisplay: TimeseriesRecord[];
  data: Timeseries;

  private backendServiceSubscription: Subscription;
  private refreshServiceSubscription: Subscription;

  error: string;

  constructor(
    private refreshService: RefreshService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (!this.navigationHelper) {
      this.navigationHelper = new MonthChartNavigationHelper(12);
    }

    this.backendServiceSubscription = this.backendService
      .getTimeseries()
      .subscribe(
        (data) => {
          this.data = data;
          this.navigationHelper.data = data;
          this.updateChart();
        },
        (error) => {
          this.error = error.message;
          if (this.data) {
            this.snackBar.open(error.message, null, { duration: 3000 });
          }
        }
      );

    this.refreshServiceSubscription = this.refreshService.events.subscribe(
      () => {
        const dateRange = this.navigationHelper.getRefreshDateRange();
        this.backendService.loadAndSubscribe(
          dateRange.from,
          dateRange.to,
          false
        );
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.backendServiceSubscription.unsubscribe();
    this.refreshServiceSubscription.unsubscribe();
  }

  private updateChart() {
    let start = 0;
    while (
      start < this.data.daten.length &&
      this.data.daten[start].t.isBefore(
        this.navigationHelper.currentFromDate,
        <unitOfTime.StartOf>this.navigationHelper.dateGranualityUnit
      )
    ) {
      start++;
    }
    let end = start + (this.navigationHelper.numberOfRecords - 1);

    // Prevent Array Index out of bounds
    if (end >= this.data.daten.length) {
      end = this.data.daten.length - 1;
      start = end - (this.navigationHelper.numberOfRecords - 1);
      this.navigationHelper.currentFromDate = this.data.daten[start].t;
    }

    this.recordsToDisplay = this.data.daten.slice(start, end + 1);
  }

  forwardButtonVisible() {
    return this.navigationHelper.isNextEnabled();
  }

  private next(event: ChartPaginationEvent): void {
    const dateRange = this.navigationHelper.handleNextEvent(event);
    if (dateRange) {
      this.backendService.loadAndSubscribe(dateRange.from, dateRange.to, true);
    } else {
      this.updateChart();
    }
  }

  private previous(event: ChartPaginationEvent): void {
    const dateRange = this.navigationHelper.handlePreviousEvent(event);
    if (dateRange) {
      this.backendService.loadAndSubscribe(dateRange.from, dateRange.to, true);
    } else {
      this.updateChart();
    }
  }

  get displayError(): boolean {
    return this.error && !this.data;
  }

  handleChartEvent(event: ChartPaginationEvent) {
    switch (event.type) {
      case ChartPaginationEventType.NEXT:
        this.next(event);
        break;
      case ChartPaginationEventType.PREVIOUS:
        this.previous(event);
        break;
      default:
    }
    return;
  }
}
