import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

export enum Source {
  internal = 1,
  backend = 2,
  network = 3,
}

export interface ErrorObject {
  code: string;
  message: string;
}

export class AppError extends Error {
  protected constructor(
    readonly source: Source,
    readonly status: number,
    message: string
  ) {
    super(message);
    Object.setPrototypeOf(this, AppError.prototype);
  }
}

export class InternalError extends AppError {
  constructor(message: string, cause?: ErrorEvent) {
    super(Source.internal, undefined, message);
    Object.setPrototypeOf(this, InternalError.prototype);
  }
}

export class NetworkError extends AppError {
  constructor(status: number, message: string) {
    super(Source.network, status, message);
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}
//https://github.com/microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
export class BusinessError extends AppError {
  constructor(status: number, private error: ErrorObject) {
    super(Source.backend, status, error.message);
    Object.setPrototypeOf(this, BusinessError.prototype);
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private subject = new Subject<any>();

  constructor(private zone: NgZone, private logger: NGXLogger) {}

  private unwrap(error: Error | any): any {
    if (error && error.rejection) {
      return this.unwrap(error.rejection);
    }
    return error;
  }

  handleError(error) {
    error = this.unwrap(error);

    // The error handler is called from outside of angular!
    // We need to notify our observers inside angular, so we run
    // the notification code within the angular zone.
    this.zone.run(() => {
      this.subject.next(error);
    });

    const element = document.querySelector('#loading-message');

    if (element) {
      element.innerHTML =
        '<div class="title-image">' +
        '<img src="assets/loading-error.svg" alt=""/>' +
        '</div>' +
        '<div class="title-text"><h3>App kann nicht gestartet werden</h3>' +
        'Ihr Browser ist möglicherweise veraltet<br/>und wird von SWM more nicht unterstützt.' +
        '<p class="mat-hint">Fehlerdetails: ' +
        error.message +
        '</p>' +
        '</div>';
    }
    this.logger.error(error);
  }

  getErrors(): Observable<any> {
    return this.subject;
  }
}
