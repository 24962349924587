import { Component, Input } from '@angular/core';
import { TimeseriesRecord } from '../../timeseries/timeseries';
import { ChartConstants } from '../../shared.constants';
import { AbstractChart } from '../abstract-chart';
import { ScreenService } from '../../../core/screen/screen.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NGXLogger } from 'ngx-logger';
import { Moment } from 'moment';
import { LabelProvider } from '../label-provider';
import { YearAndMonthMultilineLabelProvider } from './year-and-month-multiline-label-provider';
import Chart from 'chart.js';

const CHART_SCALES = {
  x: {
    grid: {
      display: false,
    },
  },
  y: {
    grid: {
      drawTicks: false,
      color: ChartConstants.GRID_LINE_COLOR,
    },
    border: {
      display: false,
    },
    ticks: {
      padding: ChartConstants.TICKS_PADDING,
    },
    beginAtZero: true,
  },

};

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent extends AbstractChart {
  @Input() records: TimeseriesRecord[];
  @Input() chartStepWidths: number[];
  @Input() timeLabelProvider: LabelProvider<Moment>;

  chartPlugins = [
    {
      id: 'app-bar-chart-after-layout',
      afterLayout: (chart: Chart.Chart) => {
        // Create new gradients after a resize so that the gradients always fit the
        // current screen size. This supports the responsive behaviour.
        const gradientHeight =
          (chart.chartArea.bottom - chart.chartArea.top) * 0.7;

        chart.data.datasets[0].backgroundColor = this.createGradient(
          ChartConstants.SWM_GREEN,
          ChartConstants.SWM_GREEN_TRANSPARENT,
          gradientHeight
        );
      },
    },
  ];

  constructor(
    screen: ScreenService,
    breakpointObserver: BreakpointObserver,
    logger: NGXLogger
  ) {
    super(screen, breakpointObserver, logger);

    this.chartOptions.scales = CHART_SCALES;
  }

  protected updateDataSets(): void {
    this.chartDataSets = [
      {
        data: this.records,
        parsing: {
          xAxisKey: 't',
          yAxisKey: 'y',
        },
      },
    ];
  }

  protected updateLabels(): void {
    if (!this.timeLabelProvider) {
      this.timeLabelProvider = new YearAndMonthMultilineLabelProvider();
    }
    this.chartLabels = [];
    for (const record of this.records) {
      const date = record.t as Moment;
      const label = this.timeLabelProvider.getLabel(date);
      this.chartLabels.push(label);
    }
  }

  protected updateAxis(): void {
    const scaling = this.calculateScaling(this.records, this.chartStepWidths);
    this.chartOptions.scales.y.ticks.stepSize = scaling.stepSize;
    this.chartOptions.scales.y.max = scaling.stepSize * scaling.lineCount;
  }
}
