import moment, { Moment } from 'moment';
import StartOf = moment.unitOfTime.StartOf;

type Inclusivity = '[]' | '(]' | '[)' | '()';

export class DateRange {
  from: Moment;
  to: Moment;

  constructor(from?: Moment, to?: Moment) {
    if (from) {
      this.from = from;
    }
    if (to) {
      this.to = to;
    }
  }

  clone(): DateRange {
    return new DateRange(
      this.from ? this.from.clone() : this.from,
      this.to ? this.to.clone() : this.to
    );
  }

  replaceEmptyDatesWithNow() {
    const now = moment();
    if (!this.from) {
      this.from = now;
    }
    if (!this.to) {
      this.to = now;
    }
  }

  includesDate(
    date: Moment,
    inclusivity: Inclusivity = '[]',
    granularity?: StartOf
  ): boolean {
    if (!date) {
      return false;
    }

    return date.isBetween(this.from, this.to, granularity, inclusivity);
  }

  contains(dataRange: DateRange): boolean {
    if (!this.from || !dataRange.from) {
      return false;
    }
    if (!this.to || !dataRange.to) {
      return false;
    }

    return (
      this.from.isSameOrBefore(dataRange.from) &&
      this.to.isSameOrAfter(dataRange.to)
    );
  }
}
