<form
  [formGroup]="formGroup"
  (ngSubmit)="submitAction.handler()"
  class="modal-dialog-form"
>
  <mat-toolbar
    color="primary"
    class="app-dialog-header"
    *ngIf="screen.device === 'mobile'"
  >
    <button mat-icon-button mat-dialog-close disableRipple="true">
      <mat-icon>close</mat-icon>
    </button>
    <div class="app-page-title flex-spacer">{{ title }}</div>
    <button
      *ngIf="submitAction"
      mat-button
      class="app-apply"
      type="submit"
      [disabled]="formGroup.invalid"
    >
      {{ submitAction.label }}
    </button>
  </mat-toolbar>

  <h2 class="title" *ngIf="screen.device === 'desktop'" mat-dialog-title>
    {{ title }}
  </h2>
  <mat-dialog-content cdkFocusInitial>
    <button
      *ngIf="resetAction && screen.device === 'mobile'"
      mat-stroked-button
      color="primary"
      tabindex="1"
      type="button"
      (click)="resetAction.handler()"
      class="app-reset"
    >
      {{ resetAction.label }}
    </button>

    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="screen.device === 'desktop'">
    <button
      *ngIf="resetAction"
      mat-button
      color="primary"
      tabindex="1"
      type="button"
      (click)="resetAction.handler()"
    >
      Zurücksetzen
    </button>
    <div class="flex-spacer"></div>
    <button mat-button mat-dialog-close color="primary">
      {{ submitAction ? 'Abbrechen' : 'Schließen' }}
    </button>
    <button
      *ngIf="submitAction"
      mat-button
      color="primary"
      tabindex="2"
      type="submit"
      [disabled]="formGroup.invalid"
    >
      {{ submitAction.label }}
    </button>
  </mat-dialog-actions>
</form>
