import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { ConfigurationService } from '../core/configuration/configuration.service';
import {
  AutocompleteLocationSuggestion,
  ChargingSessionHistoryDetails,
  ChargingStation,
  ConnectorStatusInfo,
  PrivateChargingSession,
  PublicChargingSession,
} from './ladeloesung-data';
import moment, { Moment } from 'moment';
import { catchError, map } from 'rxjs/operators';

export const LADELOESUNG_SERVICE_PATH = 'ladeloesung/';
export const PUBLIC_SERVICE_PATH = 'public/';

const MARIEN_PLATZ_MUENCHEN_COORDINATES = [11.575399, 48.137144]; //[long, lat]

@Injectable()
export class LadeloesungService {
  apiUrl: string;

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.apiUrl = config.apiUrl + LADELOESUNG_SERVICE_PATH;
  }

  getChargingStations(
    coordinates?: GeolocationCoordinates
  ): Observable<ChargingStation[]> {
    const privateChargingStations =
      this.getPrivateChargingStations(coordinates);
    const publicChargingStations =
      this.getFavoritePublicChargingStations(coordinates);
    return forkJoin([privateChargingStations, publicChargingStations]).pipe(
      map(([priv, pub]) => [...priv, ...pub])
    );
  }

  private getPrivateChargingStations(
    coordinates: GeolocationCoordinates
  ): Observable<ChargingStation[]> {
    return this.http
      .get<ChargingStation[]>(this.apiUrl + 'locations', {
        observe: 'response',
        params: coordinates
          ? new HttpParams()
              .set('latitude', coordinates.latitude)
              .set('longitude', coordinates.longitude)
          : null,
      })
      .pipe(
        map((res: HttpResponse<ChargingStation[]>) => {
          return res.body.map((chargingStation: ChargingStation) =>
            this.determineChargingStationStatus(
              new ChargingStation(chargingStation)
            )
          );
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  getFavoritePublicChargingStations(
    coordinates: GeolocationCoordinates
  ): Observable<ChargingStation[]> {
    return this.http
      .get<ChargingStation[]>(`${this.apiUrl}${PUBLIC_SERVICE_PATH}favorites`, {
        params: coordinates
          ? new HttpParams()
              .set('longitude', coordinates.longitude)
              .set('latitude', coordinates.latitude)
          : null,
      })
      .pipe(
        map((chargingStations) => {
          return chargingStations.map((cs) =>
            this.determineChargingStationStatus(new ChargingStation(cs))
          );
        })
      );
  }

  getLadevorgaenge(
    from?: Moment,
    to?: Moment
  ): Observable<ChargingSessionHistoryDetails> {
    if (!from) {
      from = moment().subtract(4, 'weeks').startOf('D');
    }

    if (!to) {
      to = moment().endOf('D');
    }

    const options = {
      params: new HttpParams()
        .set('von', from.toISOString())
        .set('bis', to.toISOString()),
    };

    return this.http
      .get<ChargingSessionHistoryDetails>(
        this.apiUrl + 'charging-sessions',
        options
      )
      .pipe(
        map((details) => {
          details.privateChargingSessions =
            this.transformPrivateChargingSessions(details);
          details.publicChargingSessions =
            this.transformRoamingChargingSessions(details);
          return details;
        })
      );
  }

  private transformPrivateChargingSessions(
    details: ChargingSessionHistoryDetails
  ): PrivateChargingSession[] {
    return details.privateChargingSessions?.map((entry) => {
      entry.sessionStart = moment(entry.sessionStart);
      entry.sessionEnd = moment(entry.sessionEnd);
      return entry;
    });
  }

  private transformRoamingChargingSessions(
    details: ChargingSessionHistoryDetails
  ): PublicChargingSession[] {
    return details.publicChargingSessions?.map((entry) => {
      entry.sessionStart = moment(entry.sessionStart);
      entry.sessionEnd = moment(entry.sessionEnd);
      return entry;
    });
  }

  getAddressSuggestions(
    value,
    coordinates?: GeolocationCoordinates
  ): Observable<AutocompleteLocationSuggestion[]> {
    let [long, lat] = coordinates
      ? [coordinates.longitude, coordinates.latitude]
      : MARIEN_PLATZ_MUENCHEN_COORDINATES;
    return this.http
      .get<AutocompleteLocationSuggestion[]>(this.apiUrl + 'autocomplete', {
        observe: 'response',
        params: new HttpParams()
          .set('text', value)
          .set('longitude', long)
          .set('latitude', lat),
      })
      .pipe(
        map((res: HttpResponse<AutocompleteLocationSuggestion[]>) => {
          return res.body;
        })
      );
  }

  findLocationsInRadius(
    longitude: number,
    latitude: number,
    maxLocationsCount: number = 3,
    radiusInMeter: number = 5000
  ): Observable<ChargingStation[]> {
    return this.http
      .get<ChargingStation[]>(
        `${this.apiUrl}${PUBLIC_SERVICE_PATH}locations-in-radius`,
        {
          params: new HttpParams()
            .set('longitude', longitude)
            .set('latitude', latitude)
            .set('radiusInMeter', radiusInMeter)
            .set('maxLocationsCount', maxLocationsCount),
        }
      )
      .pipe(
        map((chargingStations) => {
          return chargingStations.map((cs) =>
            this.determineChargingStationStatus(new ChargingStation(cs))
          );
        })
      );
  }

  determineChargingStationStatus(
    chargingStation: ChargingStation
  ): ChargingStation {
    const statusInfos = chargingStation.connectorStatusInfos;
    const totalAmountConnectors = statusInfos.length;
    const amountAvailableConnectors = statusInfos.filter(
      (connectorStatusInfo: ConnectorStatusInfo) =>
        connectorStatusInfo.status === 'STOERUNGSFREI_O_AUTO'
    ).length;
    const amountOccupiedConnectors = statusInfos.filter(
      (connectorStatusInfo: ConnectorStatusInfo) =>
        connectorStatusInfo.status === 'STOERUNGSFREI_M_AUTO'
    ).length;
    const amountConnectorsWithError = statusInfos.filter(
      (connectorStatusInfo: ConnectorStatusInfo) =>
        connectorStatusInfo.status.includes('STOERUNG_')
    ).length;

    if (amountAvailableConnectors > 0) {
      chargingStation.statusInfo = {
        status: 'available',
        message: `${amountAvailableConnectors} von ${totalAmountConnectors} verfügbar`,
      };
    } else if (amountOccupiedConnectors > 0) {
      chargingStation.statusInfo = {
        status: chargingStation.isPublic ? 'occupied' : 'charging',
        message: `${amountOccupiedConnectors} von ${totalAmountConnectors} belegt`,
      };
    } else if (amountConnectorsWithError > 0) {
      chargingStation.statusInfo = {
        status: 'unavailable',
        message: `${amountConnectorsWithError} von ${totalAmountConnectors} außer Betrieb`,
      };
    } else {
      chargingStation.statusInfo = {
        status: 'unknown',
        message: chargingStation.isPublic
          ? 'Verfügbark. unbekannt'
          : 'Unbekannt',
      };
    }
    return chargingStation;
  }
}
