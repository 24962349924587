<ng-container *ngIf="paymentOption">
  <mat-card appearance="outlined" *ngIf="paymentOption.isCompatiblePaymentMethod; else incompatiblePaymentMethod"
            [ngClass]="{'selected': isSelected}"
            (click)="setPayment(paymentOption.type)">
    <mat-card-content>
      <table>
        <tr>
          <th>
            <mat-icon [svgIcon]="paymentOption.svgIcon"></mat-icon>
          </th>
          <th>
            <h3 class="app-title flex-spacer">{{paymentOption.title}}</h3>
            <div class="info-text mat-body">{{paymentOption.cardText}}</div>
          </th>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>
  <ng-template #incompatiblePaymentMethod>
    <mat-card appearance="outlined" class="warning-card">
      <mat-card-content>
        <p>{{paymentOption.title}}</p>
        <p>{{paymentOption.cardText}}</p>
      </mat-card-content>
    </mat-card>
  </ng-template>
</ng-container>
