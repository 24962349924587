import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private PRODUCTS_KEY = 'Products';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): string[] {
    return this.storage.get(this.PRODUCTS_KEY);
  }

  public set(products: string[]) {
    this.storage.set(this.PRODUCTS_KEY, products.map(s => s.toLowerCase()));
  }

  public remove() {
    this.storage.remove(this.PRODUCTS_KEY);
  }
}
