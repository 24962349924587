<swiper-container slides-per-view="1" pagination="true" pagination-clickable="true">
  <ng-container class="slide" *ngFor="let item of swiperItems">
    <swiper-slide>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="title-container">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
            <span class="title" [innerHTML]="item.title"></span>
          </div>
          <div class="value-container">
            <span *ngIf="isDateVisible(item)" class="date">{{item.date | dateTimeFormatter:'dd. D.M'}}</span>
            <span class="value" [class.big-text]="!isDateVisible(item)"> {{item.value | appendUnit:item.unit:1:'-'}} </span>
          </div>
        </mat-card-content>
      </mat-card>
    </swiper-slide>
  </ng-container>
</swiper-container>
