import { Component, Input, OnInit } from '@angular/core';
import { ChartLegendLabel } from './chart-legend-data';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit {
  @Input()
  labels: ChartLegendLabel[];

  constructor() {}

  ngOnInit() {}

  getStyle(label: ChartLegendLabel) {
    return {
      'background-color': label.color,
    };
  }
}
