import { Component, Inject } from '@angular/core';
import moment from 'moment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScreenService } from '../../core/screen/screen.service';
import { ModalDialogAction } from '../modal-dialog/modal-dialog-form.component';
import {
  dateRangeValidator,
  errorMessages,
  maxOneYearRangeValidator,
} from '../../shared/filtering/filter.validators';
import { FormControlsParentErrorStateMatcher } from './error-state-matcher';

enum Zeitraum {
  ALL,
  DAY,
  WEEK,
  MONTH,
  USER_DEFINED,
}

export class TimeFilterCriteria {
  zeitraum: Zeitraum;
  from: moment.Moment;
  to: moment.Moment;

  constructor() {
    this.zeitraum = Zeitraum.ALL;
    this.from = moment().subtract(14, 'days').startOf('d');
    this.to = moment().endOf('d');
  }
}

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
})
export class TimeFilterComponent {
  submitAction: ModalDialogAction = {
    label: 'Anwenden',
    handler: () => this.onSubmit(),
  };
  resetAction: ModalDialogAction = {
    label: 'Alle Filter zurücksetzen',
    handler: () => this.onReset(),
  };

  ZeitraumEnum = Zeitraum;
  readonly filterFormGroup: UntypedFormGroup;
  readonly errors = errorMessages;
  readonly parentErrorMatcher = new FormControlsParentErrorStateMatcher();

  constructor(
    private screen: ScreenService,
    private dialogRef: MatDialogRef<TimeFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      filterCriteria: TimeFilterCriteria;
    }
  ) {
    const crit = this.data.filterCriteria;

    this.filterFormGroup = new UntypedFormGroup(
      {
        zeitraum: new UntypedFormControl(crit.zeitraum),
        dateFrom: new UntypedFormControl(crit.from),
        dateTo: new UntypedFormControl(crit.to),
      },
      [dateRangeValidator, maxOneYearRangeValidator]
    );

    this.updatePeriod(crit.zeitraum);

    this.filterFormGroup.get('zeitraum').valueChanges.subscribe((value) => {
      this.updatePeriod(value);
    });
  }

  onSubmit(): void {
    const crit = this.data.filterCriteria;
    crit.zeitraum = this.filterFormGroup.get('zeitraum').value;
    // Include the entire 24 hours of first and last day
    crit.from = this.filterFormGroup.get('dateFrom').value.startOf('d');
    crit.to = this.filterFormGroup.get('dateTo').value.endOf('d');
    console.log('filterCriteria to (onSubmit): ' + crit.to.format());
    this.dialogRef.close(this.data.filterCriteria);
  }

  onReset(): void {
    this.filterFormGroup.reset(
      {
        zeitraum: Zeitraum.ALL,
      },
      { emitEvent: false }
    );
    this.updatePeriod(Zeitraum.ALL);
  }

  private updatePeriod(zeitraum: Zeitraum): void {
    const dateFrom = this.filterFormGroup.get('dateFrom');
    const dateTo = this.filterFormGroup.get('dateTo');
    switch (zeitraum) {
      case Zeitraum.DAY:
        dateFrom.setValue(moment());
        dateTo.setValue(moment());
        this.disableDatepickers();
        break;
      case Zeitraum.WEEK:
        dateFrom.setValue(moment().startOf('isoWeek'));
        dateTo.setValue(moment().endOf('isoWeek'));
        this.disableDatepickers();
        break;
      case Zeitraum.MONTH:
        dateFrom.setValue(moment().startOf('M'));
        dateTo.setValue(moment().endOf('M'));
        this.disableDatepickers();
        break;
      case Zeitraum.ALL:
        dateFrom.setValue(moment().subtract(14, 'days'));
        dateTo.setValue(moment());
        this.disableDatepickers();
        break;
      case Zeitraum.USER_DEFINED:
        this.enableDatepickers();
        break;
    }
  }

  private enableDatepickers(): void {
    this.filterFormGroup.get('dateFrom').enable();
    this.filterFormGroup.get('dateTo').enable();
    this.filterFormGroup.get('dateFrom').setValidators(Validators.required);
    this.filterFormGroup.get('dateFrom').setValidators(Validators.required);
  }

  private disableDatepickers(): void {
    this.filterFormGroup.get('dateFrom').disable();
    this.filterFormGroup.get('dateTo').disable();
    this.filterFormGroup.get('dateFrom').setValidators([]);
    this.filterFormGroup.get('dateTo').setValidators([]);
  }
}
