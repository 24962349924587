import { Component, Input } from '@angular/core';
import { isEmpty } from 'lodash';

export interface ErrorDescription {
  errorTitle?: string;
  errorDescription: string;
  phoneNr?: string;
  eMail?: string;
}

export type ErrorDisplayStyle = 'flat' | 'rounded_corners';

@Component({
  selector: 'ml-error-message-with-contact-details',
  templateUrl: './error-message-with-contact-details.component.html',
  styleUrls: ['./error-message-with-contact-details.component.scss'],
})
export class ErrorMessageWithContactDetailsComponent {
  @Input()
  error: ErrorDescription;

  @Input()
  displayStyle: ErrorDisplayStyle = 'flat';

  get phoneLink(): string {
    if (this.error && this.error.phoneNr && !isEmpty(this.error.phoneNr)) {
      let phoneNr = this.error.phoneNr;
      phoneNr = phoneNr.replace('-', '');
      return `tel:${phoneNr}`;
    } else {
      return '';
    }
  }
}
