<mat-card appearance="outlined" class="info-card">
  <mat-card-content>
    <img class="info-image" src="assets/icons/icon-info-outline.svg" alt="Information" aria-hidden="false" aria-label="Information">
    <div>
      <strong>Ihre Daten werden nicht komplett angezeigt?</strong><br/>
      Leider kann es aufgrund von Wartungsarbeiten zu einer unvollständigen Darstellung kommen. Bitte versuchen Sie es später noch
      einmal.
    </div>
  </mat-card-content>
</mat-card>
