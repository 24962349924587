import { Router } from '@angular/router';
import { AppPage, AppPageComponent } from './app-page.component';
import { TopToolbarIconBotton } from '../navigation/navigation-data';

export const BACK_ACTION = 'back';

export class BackToolbarIconButton implements TopToolbarIconBotton {
  name = 'zurück';
  icon = 'arrow_back';

  actionName = BACK_ACTION;
  showOnMobileDevice = true;
  showOnDesktopDevice = true;
}

@AppPage({
  showMobileBottomNavbar: false,
  topLeftToolbarIconButton: new BackToolbarIconButton(),
  hideDesktopNavigationMenu: true,
  hideDesktopAccountMenu: true,
  showMobilePageTitle: true,
})
export class SubpageBaseComponent extends AppPageComponent {
  private prevUrl: string = null;

  constructor(public router: Router) {
    super();
    const currentNavigation = this.router.getCurrentNavigation();
    this.prevUrl = currentNavigation?.previousNavigation?.finalUrl.toString();
  }

  executeAction(actionName: string, state?: object) {
    super.executeAction(actionName);
    if (actionName === BACK_ACTION) {
      const navUrl =
        // prevUrl should not lead to a subroute to prevent endless routing loops
        this.prevUrl && !this.prevUrl.startsWith(this.router.url + '/')
          ? this.prevUrl
          : this.router.url.substring(0, this.router.url.lastIndexOf('/'));
      this.router.navigateByUrl(navUrl, {
        state,
      });
    }
  }
}
