import {
  ChartPaginationEvent,
  ChartPaginationEventType,
} from './chart-pgination-data';

export class ChartPaginationEventsHandler<T> {
  public handleChartPaginationEvent(event: ChartPaginationEvent): T {
    switch (event.type) {
      case ChartPaginationEventType.NEXT:
        return this.handleNextEvent(event);
        break;
      case ChartPaginationEventType.PREVIOUS:
        return this.handlePreviousEvent(event);
        break;
      default:
        return this.handleDefaultEvent(event);
    }
  }

  public handleNextEvent(event: ChartPaginationEvent): T {
    return null;
  }

  public handlePreviousEvent(event: ChartPaginationEvent): T {
    return null;
  }

  public handleDefaultEvent(event: ChartPaginationEvent): T {
    return null;
  }
}
