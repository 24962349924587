import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  isAmericanExpressMLoginPaymentType,
  MLoginPaymentDetails,
} from '../../../core/user/m-login-payment-details.service';
import {
  isChangedInput,
  SimpleChanges,
} from '../../../core/utils/on-changes.utils';
import { PaymentType } from '../../../ladeloesung/shared/customer-data.service';
import { getTextFor } from './payment-option-text.utils';

interface PaymentOption {
  type: PaymentType;
  title: string;
  cardText: string;
  svgIcon: string;
  isCompatiblePaymentMethod: boolean;
}

export interface PaymentOptionConfig {
  type: PaymentType;
  mLoginPaymentDetails?: MLoginPaymentDetails;
}

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.scss'],
})
export class PaymentOptionComponent implements OnChanges {
  @Input() public paymentOptionConfig: PaymentOptionConfig;
  @Input() public isSelected: boolean;
  @Output() public selectPayment = new EventEmitter<PaymentType>();

  public paymentOption: PaymentOption = null;

  public ngOnChanges(changes: SimpleChanges<PaymentOptionComponent>): void {
    const paymentOptionConfig = changes.paymentOptionConfig;
    if (isChangedInput(paymentOptionConfig)) {
      const currentPaymentOptionConfig = paymentOptionConfig.currentValue;

      switch (currentPaymentOptionConfig.type) {
        case PaymentType.MLogin:
          this.paymentOption = this.createMLoginPaymentOption(
            currentPaymentOptionConfig
          );
          break;
        case PaymentType.Invoice:
          this.paymentOption = this.createInvoicePaymentOption(
            currentPaymentOptionConfig
          );
          break;
      }
    }
  }

  public setPayment(paymentType: PaymentType): void {
      this.isSelected = true;
      this.selectPayment.emit(paymentType);
  }

  private createMLoginPaymentOption({
    mLoginPaymentDetails,
    type,
  }: PaymentOptionConfig): PaymentOption {
    const svgIcon = 'mlogin';
    let isCompatiblePaymentMethod = true;

    if (
      mLoginPaymentDetails &&
      isAmericanExpressMLoginPaymentType(mLoginPaymentDetails)
    ) {
      isCompatiblePaymentMethod = false;
    }

    return {
      type,
      svgIcon,
      isCompatiblePaymentMethod,
      ...getTextFor(PaymentType.MLogin, mLoginPaymentDetails),
    };
  }

  private createInvoicePaymentOption({
    type,
  }: PaymentOptionConfig): PaymentOption {
    return {
      type,
      svgIcon: 'invoice',
      isCompatiblePaymentMethod: true,
      ...getTextFor(PaymentType.Invoice),
    };
  }
}
