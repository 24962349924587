import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export function setValidators(
  formGroup: UntypedFormGroup,
  condition: boolean,
  controlNames: string[]
): void {
  controlNames.forEach((controlName) => {
    const control = formGroup.get(controlName);
    if (!control) return;
    if (condition) {
      control.setValidators(Validators.required);
    } else {
      control.setValidators(null);
      control.setErrors(null);
    }
  });
}

export function restrictToNumbers(event: KeyboardEvent): void {
  if (event.key === 'Backspace' || event.key === 'Delete') return;
  if (isNaN(Number(event.key))) {
    if (event.preventDefault) event.preventDefault();
    event.returnValue = false;
  }
}

export interface ZipCodeErrors extends ValidationErrors {
  invalidZipCode: {
    german: boolean;
  };
}

export interface SemicolonNotIncludedErrors extends ValidationErrors {
  includesSemicolon: boolean;
}

export class CustomValidators {
  public static germanZipCode(control: AbstractControl): ZipCodeErrors | null {
    const { value: zipCode } = control;
    const germanZipCodeRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
    const isValid = germanZipCodeRegex.test(zipCode);

    return isValid ? null : { invalidZipCode: { german: true } };
  }

  public static semicolonNotIncluded(
    control: AbstractControl
  ): SemicolonNotIncludedErrors | null {
    const { value }: { value: string } = control;
    const isValid = value == null || !value.includes(';');

    return isValid ? null : { includesSemicolon: true };
  }
}
