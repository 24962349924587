import {
  isAmericanExpressMLoginPaymentType,
  isSepaMLoginPaymentType,
  MLoginPaymentDetails,
} from '../../../core/user/m-login-payment-details.service';
import { PaymentType } from '../../../ladeloesung/shared/customer-data.service';
import { upperFirst } from 'lodash';

export interface PaymentOptionText {
  title: string;
  cardText: string;
}

export function getTextFor(
  paymentType: PaymentType,
  paymentDetails?: MLoginPaymentDetails
): PaymentOptionText | null {
  switch (paymentType) {
    case PaymentType.MLogin:
      return getMessagesForMLogin(paymentDetails);
    case PaymentType.Invoice:
      return getMessagesForInvoice();
    default:
      return null;
  }
}

function getMessagesForMLogin(
  paymentDetails: MLoginPaymentDetails
): PaymentOptionText {
  let title = 'M-Login Zahlungsmittel';
  let cardText = '';

  if (paymentDetails) {
    const { type: mLoginType, lastDigits } = paymentDetails;

    if (isSepaMLoginPaymentType(paymentDetails)) {
      const iban = `**** **** **** **** ${lastDigits}`;
      cardText = `Ihr im M-Login hinterlegtes SEPA Lastschriftmandat ${iban} wird belastet`;
    } else if (isAmericanExpressMLoginPaymentType(paymentDetails)) {
      title =
        'Bitte wählen Sie ein anderes Zahlungsmittel (Visa, Mastercard, SEPA) im M-Login.';
      cardText =
        'Das gewählte Zahlungsmittel “American Express” kann im Moment nicht für SWM More verwendet werden.';
    } else {
      const cardNumber = `**** **** **** ${lastDigits}`;
      const cardType = upperFirst(mLoginType);
      cardText = `Ihre im M-Login hinterlegte ${cardType} ${cardNumber} wird belastet.`;
    }
  } else {
    cardText = 'Sie haben kein M-Login Zahlungsmittel hinterlegt.';
  }

  return {
    title,
    cardText,
  };
}

function getMessagesForInvoice(): PaymentOptionText {
  return {
    title: 'Rechnung',
    cardText: 'Zahlen Sie bequem per Überweisung nach Erhalt Ihrer Rechnung',
  };
}
