import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

const DEFAULT_TITLE = 'SWM more';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private $appTitle: string;
  private $pageTitle: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    // Dynamic Page Title: change page title after navigation
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(filter(route => route.outlet === 'primary'))
      .subscribe(route => {
        this.$appTitle = TitleService.getLowestTitle(route, 'appTitle');
        if (this.$appTitle == null) {
          this.$appTitle = DEFAULT_TITLE;
        }
        this.$pageTitle = TitleService.getLowestTitle(route, 'pageTitle');
        this.titleService.setTitle(
          this.$pageTitle
            ? this.$pageTitle + ' | ' + this.$appTitle
            : this.$appTitle
        );
      });
  }

  get pageTitle(): string {
    return this.$pageTitle;
  }

  get appTitle(): string {
    return this.$appTitle;
  }

  private static getLowestTitle(
    route: ActivatedRoute,
    titleProperty: string
  ): string {
    // go top down and identify the lowest child
    while (route.firstChild) {
      route = route.firstChild;
    }
    // go bottom up and return the first available appTitle
    while (route.parent) {
      const title = route.snapshot.data[titleProperty];
      if (title != null) {
        // title is defined
        return title;
      } else {
        // if title is not defined search the parents for a title
        route = route.parent;
      }
    }
    return null;
  }
}
