import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(
    value: Array<any>,
    sortValue: string,
    sortDirection: 'asc' | 'desc' = 'asc',
    nestedObj?: string,
    sequence?: Array<any>
  ): Array<any> {
    const multiplier = sortDirection === 'desc' ? -1 : 1;

    value.sort((a, b) => {
      const aValue = nestedObj ? a[nestedObj][sortValue] : a[sortValue];
      const bValue = nestedObj ? b[nestedObj][sortValue] : b[sortValue];

      if (sequence && sequence.includes(aValue) && sequence.includes(bValue)) {
        return sequence.indexOf(aValue) - sequence.indexOf(bValue);
      }

      if (aValue < bValue) {
        return -1 * multiplier;
      } else if (aValue > bValue) {
        return 1 * multiplier;
      } else {
        return 0;
      }
    });

    return value;
  }
}
