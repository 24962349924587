import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

/*
 * Usage:
 *   value | convertUnit:{fromUnit}-{toUnit}[:{decimal precision}]
 * Example:
 *   {{ 2000 | convertUnit:W-kW}} kW
 *   will display: 2 kW
 */
@Pipe({
  name: 'convertUnit',
})
export class UnitConverterPipe implements PipeTransform {
  private unitConversionFunctions = {
    'w-|kw|': UnitConverterPipe.wattToAbsKilowatt,
  };

  transform(value: number, conversionPattern: string): number {
    if (isNullOrUndefined(value) || isNaN(value)) {
      return value;
    }
    conversionPattern = conversionPattern
      ? conversionPattern.toLowerCase()
      : '';
    if (this.unitConversionFunctions.hasOwnProperty(conversionPattern)) {
      return this.unitConversionFunctions[conversionPattern](value);
    }
    throw `unsupported conversion pattern: ${conversionPattern}`;
  }

  private static wattToAbsKilowatt(value: number): number {
    return Math.abs(value / Math.pow(10, 3));
  }
}
