<div class="app-title-image" [ngStyle]="style" [ngClass]="cssClasses">
  <div *ngIf="innerDescription" class="image-text">
    <div>{{ innerDescription.label }}</div>
    <div class="value">{{ innerDescription.value }}</div>
  </div>
</div>

<h3
  *ngIf="showDescription"
  class="status-image-description"
  [innerHTML]="description"
></h3>
