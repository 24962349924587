import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'a[app-link-with-icon]',
  templateUrl: './link-with-icon.component.html',
  host: {
    class: 'mp-link',
  },
  styleUrls: ['./link-with-icon.component.scss'],
})
export class LinkWithIconComponent implements OnInit {
  @Input()
  icon: string;

  constructor() {}

  ngOnInit() {}
}
