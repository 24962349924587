import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../core/configuration/configuration.service';
import { Observable } from 'rxjs';
import { LADELOESUNG_SERVICE_PATH } from '../ladeloesung.service';
import {
  CustomerData,
  CustomerDataStorageService,
} from '../../core/user/customer-data-storage.service';
import { map, tap } from 'rxjs/operators';
import { AddressOverviewData } from '../../card-ordering/card-ordering-data';

export enum PaymentType {
  MLogin = 'mlogin',
  Invoice = 'invoice',
}

export interface PaymentDetails {
  paymentType: PaymentType;
  billingAddress?: AddressOverviewData;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerDataService {
  private readonly baseUrl: string;
  private readonly paymentDetailsUrl: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private customerDataStorageService: CustomerDataStorageService
  ) {
    this.baseUrl = config.apiUrl + LADELOESUNG_SERVICE_PATH + 'customer';
    this.paymentDetailsUrl = `${this.baseUrl}/payment-details`;
  }

  public updateCompanyData(customerData: CustomerData): Observable<void> {
    return this.http
      .patch<void>(this.baseUrl + '/company', {
        name: customerData?.company?.name,
        taxId: customerData?.company?.taxId,
      })
      .pipe(
        map(() => {
          this.customerDataStorageService.setCustomerData(customerData);
        })
      );
  }

  public updateShippingAddressData(
    customerData: CustomerData,
    hasExtraAddress: boolean
  ): Observable<void> {
    return this.http
      .patch<void>(this.baseUrl + '/shipping-address', {
        address: hasExtraAddress ? customerData?.shippingAddress : null,
      })
      .pipe(
        map(() => {
          this.customerDataStorageService.setCustomerData(customerData);
        })
      );
  }

  public updatePaymentDetails(
    paymentDetails: PaymentDetails
  ): Observable<void> {
    return this.http.patch<void>(this.paymentDetailsUrl, paymentDetails);
  }

  public read(): Observable<CustomerData> {
    return this.http.get<CustomerData>(this.baseUrl).pipe(
      tap((customerData) => {
        this.customerDataStorageService.setCustomerData(customerData);
      })
    );
  }

  public getPaymentDetails(): Observable<PaymentDetails> {
    return this.http.get<PaymentDetails>(this.paymentDetailsUrl);
  }

  public get customerData(): CustomerData {
    return this.customerDataStorageService.getCustomerData();
  }
}

export function hasBillingAddress(paymentDetails: PaymentDetails): boolean {
  return (
    !!paymentDetails?.billingAddress && !!paymentDetails?.billingAddress?.street
  );
}

export function isMLoginPaymentType(paymentType: PaymentType): boolean {
  return paymentType === PaymentType.MLogin;
}
