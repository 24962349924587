<ng-container
  *ngIf="paymentOptions.mlogin?.mLoginPaymentDetails || isCommercial; else noPayment">
  <app-payment-option [paymentOptionConfig]="paymentOptions.mlogin"
                      [isSelected]="isMLoginSelected()"
                      (selectPayment)="setPayment($event)"></app-payment-option>
  <a class="mlogin-link" (click)="navigateToMLoginPaymentPage()">Daten im M-Login ändern</a>
  <ng-container *ngIf="isCommercial">
    <app-payment-option [paymentOptionConfig]="paymentOptions.invoice"
                        [isSelected]="isInvoiceSelected()"
                        (selectPayment)="setPayment($event)"></app-payment-option>
    <ng-container *ngIf="isInvoiceSelected()">
      <h3 class="app-title flex-spacer"
          style="margin-top: 12px">
        Rechnungsadresse
      </h3>
      <mat-checkbox [checked]="hasExtraAddress" (change)="onHasExtraAddressChange($event.checked)" color="primary">
        Abweichend von Kontaktadresse
      </mat-checkbox>
      <form *ngIf="hasExtraAddress" [formGroup]="form">
        <mat-form-field>
          <mat-label>Firma</mat-label>
          <input matInput [formControl]="billingAddressFormModel.companyName">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.companyName"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Vorname (optional)</mat-label>
          <input matInput [formControl]="billingAddressFormModel.givenName">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.givenName"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Nachname (optional)</mat-label>
          <input matInput [formControl]="billingAddressFormModel.familyName">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.familyName"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Straße</mat-label>
          <input matInput [formControl]="billingAddressFormModel.street">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.street"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hausnummer</mat-label>
          <input matInput [formControl]="billingAddressFormModel.houseNr">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.houseNr"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Adresszusatz (optional)</mat-label>
          <input matInput [formControl]="billingAddressFormModel.addressSupplement">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.addressSupplement"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>PLZ</mat-label>
          <input matInput [formControl]="billingAddressFormModel.zipCode" type="string-number" maxlength="5">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.zipCode"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Stadt</mat-label>
          <input matInput [formControl]="billingAddressFormModel.city">
          <mat-error>
            <app-input-error [control]="billingAddressFormModel.city"></app-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <input matInput [value]="'Deutschland'" disabled>
        </mat-form-field>
      </form>
    </ng-container>
  </ng-container>


  <mat-card appearance="outlined" class="info-card">
    <mat-card-content>
      <mat-icon aria-hidden="false" aria-label="Info Icon" svgIcon="info"></mat-icon>
      <div>Das Zahlungsmittel gilt für alle mit Ihrem Konto verknüpften Verträge und Ladekarten.</div>
    </mat-card-content>
  </mat-card>
  <ng-container *ngIf="customerUnknown">
    <ml-error-message-with-contact-details [error]="customerUnknownDescription" displayStyle="rounded_corners">
    </ml-error-message-with-contact-details>
  </ng-container>
  <button mat-raised-button
          color="primary"
          id="save-button"
          class="swm-full-button"
          [disabled]="(isEmitButtonDisabled()? true: null) || customerUnknown"
          [attr.disabled]="isEmitButtonDisabled()? true: null"
          (click)="emitPaymentDetails()">
    {{ emitPaymentDetailsButtonLabel }}
  </button>
</ng-container>
<ng-template #noPayment>
  <div class="app-centered title-icon">
    <img src="assets/icons/mladen-account-payment.svg" alt="">
  </div>
  <div class="app-centered app-headline-2">
    Kein Zahlungsmittel
  </div>
  <div class="app-centered">
    Das Zahlungsmittel gilt nach dem Hinzufügen für alle mit Ihrem Konto verknüpften Verträge und Ladekarten.
  </div>
  <button mat-raised-button color="primary" class="swm-full-button mat-body-2" (click)="navigateToMLoginPaymentPage()">
    Zahlungsmittel im
    <mat-icon svgIcon="mlogin" class="mlogin-icon"></mat-icon>
    Login hinzufügen
  </button>
</ng-template>

