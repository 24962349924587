import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AcceptLanguageInterceptor } from './accept-language.interceptor';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { CorrelationIdInterceptor } from './correlation-id.interceptor';

export const HTTP_INTERCEPTOR_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AcceptLanguageInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CorrelationIdInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
