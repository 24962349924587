import { Chart, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { ChartConstants } from '../shared.constants';
import { ScreenService } from '../../core/screen/screen.service';
import { TimeseriesRecord } from '../timeseries/timeseries';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BaseChartDirective } from 'ng2-charts';
import { Directive, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

const PORTRAIT = '(orientation: portrait)';
const Y_TICKS = 8;

@Directive()
export abstract class AbstractChart implements OnInit, OnChanges, OnDestroy {
  @ViewChild(BaseChartDirective)
  private $chartRef: BaseChartDirective;
  private $orientationPortrait = this.breakpointObserver.isMatched([PORTRAIT]);
  private breakpointObserverSubscription: Subscription;

  chartDataSets: ChartDataset<any, any>[];
  chartLabels: string[][];

  chartOptions: ChartOptions<any> = {
    responsive: true,
    aspectRatio: this.$orientationPortrait
      ? this.aspectRatioPortrait
      : this.aspectRatioLandscape,
    animation: false,
    events: [],
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
        hitRadius: 0,
      },
    },
    scales: null,
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
        displayColors: true,
        callbacks: {
          labelColor: (tooltipItem: TooltipItem<any>) => {
            return {
              borderColor: tooltipItem.dataset.borderColor,
              backgroundColor: tooltipItem.dataset.backgroundColor,
            };
          },
        },
      },
      legend: null,
    },
  };

  protected constructor(
    protected screen: ScreenService,
    private breakpointObserver: BreakpointObserver,
    protected logger: NGXLogger,
    private aspectRatioPortrait: number = 1.5,
    private aspectRatioLandscape: number = 2.8
  ) {
    Chart.defaults.font.family = ChartConstants.CHART_FONT_FAMILY;
    Chart.defaults.color = ChartConstants.BLACK;
    Chart.defaults.font.size = ChartConstants.CHART_FONT_SIZE;
  }

  ngOnInit(): void {
    this.breakpointObserverSubscription = this.breakpointObserver
      .observe([PORTRAIT])
      .subscribe((result) => {
        if (
          this.$chartRef &&
          this.$chartRef.chart &&
          result.matches !== this.$orientationPortrait
        ) {
          if (result.matches) {
            this.$chartRef.chart.options.aspectRatio = this.aspectRatioPortrait;
            this.$chartRef.chart.resize();
            this.logger.trace(
              'Chart breakpointObserver: set Ratio to ' +
                this.aspectRatioPortrait
            );
            this.$orientationPortrait = true;
          } else {
            this.$chartRef.chart.options.aspectRatio =
              this.aspectRatioLandscape;
            this.$chartRef.chart.resize();
            this.logger.trace(
              'Chart breakpointObserver: set Ratio to ' +
                this.aspectRatioLandscape
            );
            this.$orientationPortrait = false;
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateLabels();
    this.updateAxis();
    this.updateDataSets();
  }

  ngOnDestroy(): void {
    this.breakpointObserverSubscription.unsubscribe();
  }

  protected updateLabels(): void {}

  protected abstract updateAxis(): void;

  protected abstract updateDataSets(): void;

  protected calculateScaling(
    records: TimeseriesRecord[],
    chartStepWidths: number[],
    chartTickNr: number = Y_TICKS
  ): { stepSize: number; lineCount: number } {
    const yValues = records.map((value) => value.y);
    const max: number = this.getMaxValue(yValues) * 1.1 || 60;
    const min: number = this.getMinValue(yValues);
    return this.calculateScalingFromMax(min, max, chartTickNr, chartStepWidths);
  }

  protected calculateScalingFromMax(
    min: number,
    max: number,
    chartTickNr: number,
    chartStepWidths: number[]
  ) {
    if (min === 0) {
      chartTickNr++;
    }

    const optimalStepSize = Math.round(max / chartTickNr);

    // find smallest possible step size fitting the values, or fall back to calculating
    let i = 0;
    while (chartStepWidths[i] < optimalStepSize) {
      i++;
    }
    const closestStepSize =
      chartStepWidths[i] ||
      this.calculateDynStepSize(chartStepWidths, optimalStepSize);

    const lineCount = Math.round(max / closestStepSize);

    return { stepSize: closestStepSize, lineCount };
  }

  // calculates a step size that is the smallest multiple of the highest predefined size
  private calculateDynStepSize(chartStepWidths: number[], optimalStepSize) {
    const maxDefinedStepSize = this.getMaxValue(chartStepWidths);
    return Math.ceil(optimalStepSize / maxDefinedStepSize) * maxDefinedStepSize;
  }

  protected createGradient(
    colorTop: string,
    colorBottom: string,
    height: number
  ): CanvasGradient {
    const canvas = document.querySelector('canvas') as HTMLCanvasElement;

    const gradient = canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, colorTop);
    gradient.addColorStop(1, colorBottom);

    return gradient;
  }

  get chartRef(): BaseChartDirective {
    return this.$chartRef;
  }

  getMinValue(values: number[]) {
    let len = values.length;
    let min = Infinity;

    while (len--) {
      min = values[len] < min ? values[len] : min;
    }
    return min;
  }

  getMaxValue(values: number[]) {
    let len = values.length;
    let max = -Infinity;

    while (len--) {
      max = values[len] > max ? values[len] : max;
    }
    return max;
  }
}
