import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Breakpoints } from '../../core/screen/screen.service';

@Injectable()
export class ModalDialogService {
  isMobile: Observable<BreakpointState> = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
  ]);

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  open(
    component: ComponentType<any>,
    config: MatDialogConfig = {}
  ): MatDialogRef<ComponentType<any>> {
    const d = this.dialog.open(component, config);

    const smallDialogSubscription = this.isMobile.subscribe(size => {
      if (size.matches) {
        d.updateSize('100vw', '100vh');
        document.body.classList.add('fullscreen-active');
      } else {
        d.updateSize(config.width, config.height);
        document.body.classList.remove('fullscreen-active');
      }
    });

    d.beforeClosed().subscribe(() => {
      document.body.classList.remove('fullscreen-active');
    });

    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
    });

    return d;
  }
}
