import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private ADDRESS_KEY = 'Address';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): Address {
    return this.storage.get(this.ADDRESS_KEY);
  }

  public set(address: Address) {
    this.storage.set(this.ADDRESS_KEY, address);
  }

  public remove() {
    this.storage.remove(this.ADDRESS_KEY);
  }
}

export interface Address {
  city: string;
  country: string;
  houseNr: string;
  street: string;
  zipCode: string;
  addressSupplement: string;
}
