<div class="app-slider">
  <div class="app-slider-placeholder">
    <label class="mat-form-field-label">{{ placeholder }}</label>
  </div>
  <mat-slider
    [min]="min"
    [max]="max"
    class="app-slider-component"
    #ngSlider>
    <input matSliderThumb
           [value]="value"
           (input)="onInputChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
           #ngSliderThumb="matSliderThumb" />
  </mat-slider>
  <mat-form-field class="app-slider-text">
    <input
      type="number"
      min="0"
      step="1"
      required
      matInput
      [value]="value"
      (input)="onInputChange($event)"
      placeholder=""
    />
    <span matIconSuffix>{{ unit }}</span>
  </mat-form-field>
</div>
