import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { PopupComponent } from './popup/popup.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { ModalDialogFormComponent } from './modal-dialog/modal-dialog-form.component';
import { ModalDialogService } from './modal-dialog/modal-dialog.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import 'chartjs-adapter-moment';
import { TimeseriesChartComponent } from './charts/timeseries-chart/timeseries-chart.component';
import { StatusIndicatorDirective } from './status-indicator/status-indicator.directive';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { TimeFilterComponent } from './filtering/time-filter.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AccountComponent } from './account/account.component';
import { StatusImageComponent } from './status-image/status-image.component';
import { ChartPaginationComponent } from './charts/chart-pagination/chart-pagination.component';
import { ChartLegendComponent } from './charts/chart-legend/chart-legend.component';
import { SliderComponent } from './slider/slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LinkWithIconComponent } from './link/link-with-icon/link-with-icon.component';
import { UnitConverterPipe } from './pipes/unit-converter.pipe';
import { UnitAppenderPipe } from './pipes/unit-appender.pipe';
import { DateTimeFormatterPipe } from './pipes/datetime-formatter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { InfoSwiperComponent } from './info-swiper/info-swiper.component';
import { MatCardModule } from '@angular/material/card';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconWithContentPageComponent } from './icon-with-content-page/icon-with-content-page.component';
import { TitleWithCloseButtonToolbarRowComponent } from './title-with-close-button-toolbar-row/title-with-close-button-toolbar-row.component';
import { AccountWrapperComponent } from './account-wrapper/account-wrapper.component';
import { AccountHeaderComponent } from './account-header/account-header.component';
import { StringNumberInputDirective } from './form/string-number-input.directive';
import { InputErrorComponent } from './form/input-error/input-error.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentOptionComponent } from './payment-details/payment-option/payment-option.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { ErrorMessageWithContactDetailsComponent } from './error-message/error-message-with-contact-details.component';
import { MaintenanceInfoComponent } from './maintenance-info/maintenance-info.component';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

@NgModule({
  declarations: [
    NavigationComponent,
    AccountComponent,
    StatusIndicatorComponent,
    StatusIndicatorDirective,
    TimeFilterComponent,
    WelcomePageComponent,
    PopupComponent,
    ModalDialogFormComponent,
    PlaceholderComponent,
    TimeseriesChartComponent,
    BarChartComponent,
    StatusImageComponent,
    ChartPaginationComponent,
    ChartLegendComponent,
    SliderComponent,
    LinkWithIconComponent,
    UnitConverterPipe,
    UnitAppenderPipe,
    DateTimeFormatterPipe,
    SortPipe,
    SafeUrlPipe,
    InfoSwiperComponent,
    ProgressSpinnerComponent,
    IconWithContentPageComponent,
    TitleWithCloseButtonToolbarRowComponent,
    AccountWrapperComponent,
    AccountHeaderComponent,
    StringNumberInputDirective,
    InputErrorComponent,
    PaymentDetailsComponent,
    PaymentOptionComponent,
    ShippingAddressComponent,
    ErrorMessageWithContactDetailsComponent,
    MaintenanceInfoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgChartsModule,
    MatSliderModule,
    FormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgOptimizedImage,
  ],
  exports: [
    AccountComponent,
    NavigationComponent,
    StatusIndicatorComponent,
    StatusIndicatorDirective,
    TimeFilterComponent,
    WelcomePageComponent,
    PopupComponent,
    PlaceholderComponent,
    ModalDialogFormComponent,
    TimeseriesChartComponent,
    StatusImageComponent,
    ChartPaginationComponent,
    ChartLegendComponent,
    SliderComponent,
    LinkWithIconComponent,
    UnitConverterPipe,
    UnitAppenderPipe,
    DateTimeFormatterPipe,
    SortPipe,
    SafeUrlPipe,
    InfoSwiperComponent,
    ProgressSpinnerComponent,
    IconWithContentPageComponent,
    TitleWithCloseButtonToolbarRowComponent,
    AccountWrapperComponent,
    AccountHeaderComponent,
    StringNumberInputDirective,
    InputErrorComponent,
    PaymentDetailsComponent,
    PaymentOptionComponent,
    ShippingAddressComponent,
    ErrorMessageWithContactDetailsComponent,
    MaintenanceInfoComponent,
  ],
  providers: [ModalDialogService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
