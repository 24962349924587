<div class="app-placeholer" *ngIf="show || error">
  <div class="app-image"></div>
  <div class="app-message" *ngIf="show">
    {{ message }}<ng-container *ngIf="detail">: {{ detail }}</ng-container>
  </div>
  <div class="app-message" *ngIf="error">
    {{ message ? message : error
    }}<ng-container *ngIf="message">: {{ error }}</ng-container>
  </div>
</div>
