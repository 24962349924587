import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
})
export class PlaceholderComponent {
  @Input() error: string;
  @Input() show: boolean;
  @Input() message: string;
  @Input() detail: string;

  constructor() {}
}
