import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { isEmpty } from 'lodash';
import { TimeseriesRecord } from '../timeseries/timeseries';

@Pipe({
  name: 'dateTimeFormatter',
})
export class DateTimeFormatterPipe implements PipeTransform {
  transform(
    value: string | Moment,
    format?: string,
    defaultValue: string = '-'
  ): string {
    if (isEmpty(value)) {
      return defaultValue;
    }
    let date: Moment = TimeseriesRecord.stringToMoment(value);
    return date.format(format);
  }
}
