<div class="title-and-content-align-{{showStatusIcon?'center':'left'}}">
<h2 mat-dialog-title>
  <div *ngIf="showStatusIcon" class="app-status-image" [ngClass]="status"></div>
  {{ title }}
</h2>
<div mat-dialog-content [innerHTML]="content"></div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close class="swm-full-button" color="primary" tabindex="-1" [matDialogClose]="true">
    {{ label }}
  </button>
</div>
</div>
