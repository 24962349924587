import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Input() color: string;

  get colorClass(): string {
    return this.color ? 'mat-' + this.color : null;
  }
}
