import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private subject = new Subject<string>();

  constructor() {}

  do() {
    this.subject.next('RELOAD');
  }

  get isEnabled(): boolean {
    return this.subject.observers.length > 0;
  }

  get events(): Observable<string> {
    return this.subject;
  }
}
