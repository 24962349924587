<div class="app-title-image"></div>
<h1 class="app-headline">
  {{ title }}
</h1>
<p style="text-align: center">
  Ihre Registrierung war erfolgreich.
</p>
<div class="app-form app-centered">
  <button mat-flat-button color="accent" routerLink="..">
    Los geht's
  </button>
</div>
