import { Component, OnInit } from '@angular/core';
import { ScreenService } from '../../core/screen/screen.service';
import { UserService } from '../../core/user/user.service';
import { SubpageBaseComponent } from '../component-base/subpage-base.component';
import { Router } from '@angular/router';
import { AppPage } from '../component-base/app-page.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
@AppPage({
  useAppFullWidth: true,
})
export class AccountComponent extends SubpageBaseComponent implements OnInit{
  constructor(
    public screen: ScreenService,
    public user: UserService,
    router: Router
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.user.getUserInfo().subscribe();
  }

  get isLadeloesung(): boolean {
    return this.user.getTargetApp() === 'ladeloesung';
  }
}
