import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private PROFILE_KEY = 'Profile';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): Profile {
    return this.storage.get(this.PROFILE_KEY);
  }

  public set(profile: Profile) {
    this.formatProfile(profile);
    this.storage.set(this.PROFILE_KEY, profile);
  }

  public remove() {
    this.storage.remove(this.PROFILE_KEY);
  }

  private formatProfile(profile): void {
    profile.anrede =
      profile.anrede?.slice(0, 1) + profile.anrede?.slice(1).toLowerCase();
  }
}

export interface Profile {
  givenName: string;
  familyName: string;
  anrede: string;
}
