import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { restrictToNumbers, setValidators } from '../form/utils';
import { CustomerData } from '../../core/user/customer-data-storage.service';

const addressFormControlNames = [
  'addressCompanyName',
  'familyName',
  'givenName',
  'street',
  'houseNr',
  'addressSupplement',
  'zipCode',
  'city',
];

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss'],
})
export class ShippingAddressComponent implements OnInit, OnChanges {
  @Input() formGroup: UntypedFormGroup = this.formBuilder.group({});
  @Output() formGroupChange = new EventEmitter<UntypedFormGroup>();
  @Input() isCommercial: boolean;
  @Input() companyName: string;
  @Input() hasExtraAddress: boolean;
  @Output() hasExtraAddressChange = new EventEmitter<boolean>();
  @Input() accountStyle: boolean;
  @Input() customerData: CustomerData;
  public validate = restrictToNumbers;
  public readonly accountPropertiesMaxLength: number = 100;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    addressFormControlNames.forEach((formControlName) => {
      this.formGroup.addControl(formControlName, new UntypedFormControl(null));
    });
    this.updateValidators();
    this.formGroup.valueChanges.subscribe((_) => {
      this.formGroupChange.emit(this.formGroup);
    });
    this.setCompanyName();
    this.setAddressData(this.customerData)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerData) {
      this.setAddressData(this.customerData);
    }
    if (changes.companyName) {
      this.setCompanyName();
    }
    if (changes.isCommercial) {
      this.setCompanyName();
    }
    this.updateValidators();
  }

  emitHasExtraAddressChange(): void {
    this.updateValidators();
    this.hasExtraAddressChange.emit(this.hasExtraAddress);
  }

  updateValidators(): void {
    setValidators(this.formGroup, this.hasExtraAddress && !this.isCommercial, [
      'givenName',
      'familyName',
    ]);
    setValidators(this.formGroup, this.hasExtraAddress && this.isCommercial, [
      'addressCompanyName',
    ]);
    setValidators(this.formGroup, this.hasExtraAddress, [
      'street',
      'houseNr',
      'zipCode',
      'city',
    ]);
  }

  private setAddressData(customerData: CustomerData) {
    if (!customerData?.shippingAddress) return;
    this.hasExtraAddress = true;
    this.emitHasExtraAddressChange();
    this.formGroup.patchValue({
      givenName: customerData.shippingAddress.givenName,
      familyName: customerData.shippingAddress.familyName,
      addressCompanyName: this.isCommercial ? this.companyName : null,
      addressSupplement: customerData.shippingAddress.addressSupplement,
      street: customerData.shippingAddress.street,
      houseNr: customerData.shippingAddress.houseNr,
      zipCode: customerData.shippingAddress.zipCode,
      city: customerData.shippingAddress.city,
    });
  }

  private setCompanyName() {
    this.formGroup.patchValue({
      addressCompanyName: this.isCommercial ? this.companyName : null,
    });
  }
}
