import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private EMAIL_KEY = 'EmailAddress';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): string {
    return this.storage.get(this.EMAIL_KEY);
  }

  public set(email: string) {
    this.storage.set(this.EMAIL_KEY, email);
  }

  public remove() {
    this.storage.remove(this.EMAIL_KEY);
  }
}
