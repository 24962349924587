import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  private PHONE_KEY = 'PhoneNumbers';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public get(): PhoneNumbers {
    return this.storage.get(this.PHONE_KEY);
  }

  public set(phoneNumbers: PhoneNumbers) {
    this.storage.set(this.PHONE_KEY, phoneNumbers);
  }

  public remove() {
    this.storage.remove(this.PHONE_KEY);
  }
}

export class PhoneNumbers {
  line: string;
  mobile: string;

  constructor(home: string, mobile: string) {
    this.line = home;
    this.mobile = mobile;
  }
}
