import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';
import { isNullOrUndefined } from 'util';

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
  required: 'Dies ist ein Pflichtfeld',
  dateRange:
    'Prüfen Sie bitte Ihren gewählten Zeitraum. Dieser ist leider ungültig.',
  maxDuration: 'Maximal ein Monat auswählbar',
  maxRange:
    'Prüfen Sie bitte Ihren gewählten Zeitraum. Es kann max. ein Jahr gewählt werden.',
  noDate: 'Ungültiges Datum',
};

export const dateRangeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const start: Moment = fg.get('dateFrom').value;
  const end: Moment = fg.get('dateTo').value;
  if (isNullOrUndefined(start) || isNullOrUndefined(end)) {
    return null;
  }
  return start.valueOf() <= end.valueOf() ? null : { dateRange: true };
};

export const maxDurationValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const start: Moment = fg.get('dateFrom').value;
  const end: Moment = fg.get('dateTo').value;
  if (
    start == null ||
    !moment.isMoment(start) ||
    end == null ||
    !moment.isMoment(end)
  ) {
    return { maxDuration: true };
  }
  const timeDiff = end.diff(start, 'months', true);
  return timeDiff <= 1 ? null : { maxDuration: true };
};

export const dateValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (isNullOrUndefined(control)) {
    return { noDate: true };
  }
  const date = moment(control.value);
  return date.isValid() ? null : { noDate: true };
};

export const maxOneYearRangeValidator: ValidatorFn = (control: UntypedFormGroup) => {
  if (
    isNullOrUndefined(control.get('dateFrom').value) ||
    isNullOrUndefined(control.get('dateTo').value)
  ) {
    return null;
  }
  if (
    !moment(control.get('dateTo').value).isValid() ||
    !moment(control.get('dateFrom').value).isValid()
  ) {
    return null;
  }
  return control
    .get('dateTo')
    .value.diff(control.get('dateFrom').value, 'days') > 364
    ? { maxRange: true }
    : null;
};
