import { Injectable } from '@angular/core';
import { OauthService } from '../oauth/oauth.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../configuration/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { finalize, map } from 'rxjs/operators';
import { EmailService } from './email.service';
import { ProductsService } from './products.service';
import { Address, AddressService } from './address.service';
import { PhoneNumbers, PhoneService } from './phone.service';
import { Profile, ProfileService } from './profile.service';
import {
  MLoginPaymentDetails,
  MLoginPaymentDetailsService,
} from './m-login-payment-details.service';
import { CustomerDataStorageService } from './customer-data-storage.service';

const DUMMY_EMAIL = 'test@swm-more.de';
const DUMMY_PRODUCTS = ['PARTNERKRAFT'];
export enum QUERY_PARAMS {
  state = 'state',
  doneRedirectUri = 'done_redirect_uri',
  clientId = 'client_id',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private resource: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private oauthService: OauthService,
    private emailService: EmailService,
    private addressService: AddressService,
    private phoneService: PhoneService,
    private profileService: ProfileService,
    private productsService: ProductsService,
    private paymentService: MLoginPaymentDetailsService,
    private customerDataStorageService: CustomerDataStorageService,
    private configurationService: ConfigurationService,
    private logger: NGXLogger
  ) {
    this.resource = configurationService.apiUrl + 'authorization';
  }

  private handleError(error) {
    this.logger.error('Login fehlgeschlagen', error);
    this.router.navigateByUrl('/login-failed', {
      replaceUrl: true,
    });
  }

  public login(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.oauthService.handleRedirect().subscribe(
        (result) => {
          if (result) {
            this.logger.debug('OAUTH Callback-Parameter gefunden', result);
            this.doLogin(
              result.code,
              result.codeVerifier,
              result.redirectUri
            ).subscribe(
              (response) => {
                this.logger.debug('Login erfolgreich');
                this.saveUserInfo(response);
                this.router.navigateByUrl(result.savedState.requestUrl, {
                  replaceUrl: true,
                });
                observer.next(true);
                observer.complete();
              },
              (error) => this.handleError(error)
            );
          } else {
            this.logger.debug('OAUTH Authentifizierung erforderlich');
            this.clearCredentials(location.pathname + location.search);
            observer.next(false);
            observer.complete();
          }
        },
        (error) => this.handleError(error)
      );
    });
  }

  public getUserInfo(): Observable<AuthorizationResponse> {
    return this.http
      .get<AuthorizationResponse>(
        this.configurationService.apiUrl + 'getUserInfo'
      )
      .pipe(
        map((response) => {
          this.saveUserInfo(response);
          return response;
        })
      );
  }

  private saveUserInfo(response: AuthorizationResponse): void {
    this.emailService.set(response.emailAddress);
    this.addressService.set(response.address);
    this.productsService.set(response.products);
    this.phoneService.set(new PhoneNumbers(response.line, response.mobile));
    this.profileService.set(response.profile);
    this.paymentService.set(response.payment);
  }

  public logout() {
    this.doLogout()
      .pipe(finalize(() => this.clearCredentials(location.pathname)))
      .subscribe();
  }

  public clearCredentials(redirectTo?: string) {
    if (environment.production) {
      this.emailService.remove();
      this.productsService.remove();
      this.addressService.remove();
      this.phoneService.remove();
      this.profileService.remove();
      this.paymentService.remove();
      this.customerDataStorageService.remove();
      this.oauthService.initAuthorizationCodeFlow({
        requestUrl: redirectTo ? redirectTo : '',
      });
    } else {
      this.emailService.set(DUMMY_EMAIL);
      this.productsService.set(DUMMY_PRODUCTS);
      location.reload();
    }
  }

  public registerProduct(productType: string, pin: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.doUpdate(productType, pin).subscribe((response) => {
        this.saveUserInfo(response);
        observer.next(true);
        observer.complete();
      });
    });
  }

  get email(): string {
    return this.emailService.get();
  }

  get address(): Address {
    return this.addressService.get();
  }

  get phoneNumbers(): PhoneNumbers {
    return this.phoneService.get();
  }

  get profile(): Profile {
    return this.profileService.get();
  }

  get payment(): MLoginPaymentDetails {
    return this.paymentService.get();
  }

  get profileUrl(): string {
    return this.configurationService.profileUrl;
  }

  get paymentUrl(): string {
    return this.configurationService.paymentUrl;
  }

  public getTargetApp(): string {
    const products: string[] = this.productsService.get();
    // currently users can only have 1 app, therefore take first product
    return products ? products[0] : null;
  }

  public isAuthenticated(): boolean {
    return this.emailService.get() != null;
  }

  private doLogin(
    code: string,
    codeVerifier: string,
    redirectUri: string
  ): Observable<AuthorizationResponse> {
    const params: AuthorizationRequest = {
      code,
      codeVerifier,
      redirectUri,
    };
    return this.http.post<AuthorizationResponse>(this.resource, params);
  }

  private doLogout(): Observable<any> {
    return this.http.delete(this.resource);
  }

  private doUpdate(
    productType: string,
    pin: string
  ): Observable<AuthorizationResponse> {
    const params: UpdateRequest = { productType, pin };
    return this.http.patch<AuthorizationResponse>(this.resource, params);
  }

  public saveCardOrderingState(state): string {
    return this.oauthService.saveStateInSessionStorage(state);
  }

  public getCardOrderingState(): any {
    return this.oauthService.getStateFromSessionStorage(QUERY_PARAMS.state);
  }

  public getLocation(): Observable<GeolocationCoordinates> {
    return new Observable((observer: Subscriber<GeolocationCoordinates>) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            observer.next(position.coords);
          },
          (_) => {
            observer.error('Geolocation is not enabled');
          }
        );
      } else {
        observer.error('Geolocation not available');
      }
    });
  }

  public navigateToMLogin(redirectUrl: string, stateId?: string): void {
    const currentUrl = window.location.origin + window.location.pathname;
    const stateQueryParam = stateId ? `?${QUERY_PARAMS.state}=` + stateId : '';
    const doneRedirectUri = currentUrl + stateQueryParam;
    window.location.href =
      redirectUrl +
      `?${QUERY_PARAMS.doneRedirectUri}=` +
      doneRedirectUri +
      `&${QUERY_PARAMS.clientId}=` +
      this.configurationService.clientId;
  }
}

interface AuthorizationRequest {
  code: string;
  codeVerifier: string;
  redirectUri: string;
}

interface UpdateRequest {
  productType: string;
  pin: string;
}

export interface AuthorizationResponse {
  emailAddress: string;
  products: string[];
  address: Address;
  line: string;
  mobile: string;
  profile: Profile;
  payment: MLoginPaymentDetails;
}
