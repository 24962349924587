import { Inject, Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import sha256 from 'fast-sha256';
import base64url from 'base64url';
import { Observable } from 'rxjs';
import { InternalError } from '../error-handler/error-handler';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  constructor(
    private config: ConfigurationService,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {}

  private bufferToBase64(buf: Uint8Array): string {
    const binstr = Array.prototype.map
      .call(buf, (ch) => {
        return String.fromCharCode(ch);
      })
      .join('');
    return btoa(binstr);
  }

  private base64ToBuffer(base64: string): Uint8Array {
    const binstr = atob(base64);
    const buf = new Uint8Array(binstr.length);
    Array.prototype.forEach.call(binstr, (ch, i) => {
      buf[i] = ch.charCodeAt(0);
    });
    return buf;
  }

  private strToBuffer(str: string): Uint8Array {
    const arrayBuffer = new ArrayBuffer(str.length * 1);
    const newUint = new Uint8Array(arrayBuffer);
    for (let i = 0; i < newUint.length; i++) {
      newUint[i] = str.charCodeAt(i);
    }
    return newUint;
  }

  private getRandomValues(array: Uint32Array) {
    if (window.crypto) {
      window.crypto.getRandomValues(array);
    } else {
      for (let i = 0; i < array.length; i++) {
        array[i] = Math.floor(Math.random() * Math.floor(4294967295));
      }
    }
  }

  private createRandomString(length = 43): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const scale = 4294967295 / (possible.length - 1); // Uint32 max value
    const array = new Uint32Array(length);
    this.getRandomValues(array);

    for (let i = 0; i < length; i++) {
      const index = Math.floor(array[i] / scale);
      text += possible.charAt(index);
    }
    return text;
  }

  private createCodeChallenge(codeVerifier: string): string {
    const hash = sha256(this.strToBuffer(codeVerifier));
    return base64url.fromBase64(this.bufferToBase64(hash));
  }

  private saveState(key: string, state: OauthState): void {
    this.storage.set(key, state);
  }

  private getQueryParams(): object {
    let search = window.location.search;

    search = decodeURIComponent(search);

    const questionMarkPosition = search.indexOf('?');

    if (questionMarkPosition > -1) {
      search = search.substr(questionMarkPosition + 1);
    } else {
      search = search.substr(1);
    }

    return this.parseQueryString(search);
  }

  private parseQueryString(queryString: string): object {
    const data = {};
    let pairs: string[];
    let pair: string;
    let separatorIndex: number;
    let escapedKey: string;
    let escapedValue: string;
    let key: string;
    let value: string;

    if (queryString === null) {
      return data;
    }

    pairs = queryString.split('&');

    for (pair of pairs) {
      separatorIndex = pair.indexOf('=');

      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }

      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);

      if (key.substr(0, 1) === '/') {
        key = key.substr(1);
      }

      data[key] = value;
    }

    return data;
  }

  public saveStateInSessionStorage(state: any): string {
    const randomString = this.createRandomString(40);
    this.saveState(randomString, state);
    return randomString;
  }

  public getStateFromSessionStorage(stateId: string): any {
    const parts = this.getQueryParams();
    const queryParam = decodeURIComponent(parts[stateId]);
    const result = this.storage.get(queryParam);
    this.storage.remove(queryParam);
    return result;
  }

  public initAuthorizationCodeFlow(savedState?: any) {
    const redirectUri = window.location.pathname.startsWith('/card-ordering')
      ? window.location.origin + '/card-ordering/'
      : window.location.origin + '/';
    const nonce = this.createRandomString(40);
    const codeVerifier = this.createRandomString();
    const codeChallenge = this.createCodeChallenge(codeVerifier);
    // TODO: as soon as prod is also requesting the user to allow access to
    //  additional info, the scope can be unified
    const scope =
      'openid profile.ro comm.email.ro comm.phone.line.ro ' +
      'comm.phone.mobile.ro address.ro payment.wallet.ro';
    const oauthState = new OauthState();
    oauthState.codeVerifier = codeVerifier;
    oauthState.redirectUri = redirectUri;
    oauthState.savedState = savedState;

    this.saveState(nonce, oauthState);

    const url =
      this.config.loginUrl +
      '?response_type=code' +
      '&client_id=' +
      encodeURIComponent(this.config.clientId) +
      '&redirect_uri=' +
      encodeURIComponent(redirectUri) +
      '&state=' +
      encodeURIComponent(nonce) +
      '&code_challenge=' +
      codeChallenge +
      '&code_challenge_method=S256' +
      '&scope=' +
      encodeURIComponent(scope);

    window.location.href = url;
  }

  public handleRedirect(): Observable<OauthState> {
    const observable = new Observable<OauthState>((observer) => {
      const parts = this.getQueryParams();
      const stateParam = 'state';
      const codeParam = 'code';

      if (parts[stateParam] !== undefined) {
        const nonce = decodeURIComponent(parts[stateParam]);
        const result = this.storage.get(nonce);
        this.storage.remove(nonce);
        if (result) {
          result.code = decodeURIComponent(parts[codeParam]);
          observer.next(result);
        } else {
          observer.error(
            new InternalError('Kein gültiger Anmeldezustand vorhanden')
          );
        }
      } else {
        observer.next(null);
      }
      observer.complete();
    });
    return observable;
  }
}

export class OauthState {
  code: string;
  codeVerifier: string;
  redirectUri: string;
  savedState: any;
}
