<div class="error-message" *ngIf="error" [ngClass]="{'rounded-corners' : displayStyle == 'rounded_corners'}">
  <strong *ngIf="error.errorTitle" class="mat-subtitle-1">{{ error.errorTitle }}</strong>
  <p>{{ error.errorDescription }}</p>

  <div class="contact-details" *ngIf="error.eMail || error.phoneNr">
    <ng-container *ngIf="error.eMail">
      <div>
        <img src="assets/icons/icon-mail-error.svg" alt=""/>
        <a class="mat-subtitle-2" href="mailto:{{error.eMail}}">{{ error.eMail }}</a>
      </div>
      <div class="flex-spacer"></div>
    </ng-container>
    <div *ngIf="error.phoneNr">
      <img src="assets/icons/icon-phone-error.svg" alt=""/>
      <a [href]="phoneLink" class="mat-subtitle-2">{{ error.phoneNr }}</a>
    </div>
  </div>
</div>
