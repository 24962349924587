import { ChartPaginationEventsHandler } from '../chart-pagination/chart-pagination-events-handler';
import { TimeseriesChartComponent } from './timeseries-chart.component';
import { Moment } from 'moment';
import { AbstractTimeseriesService } from '../../timeseries/abstract-timeseries.service';
import { RefreshService } from '../../../core/refresh/refresh.service';
import { DateRange } from '../../date/date-data';
import { from } from 'rxjs';
import { ChartPaginationEvent } from '../chart-pagination/chart-pgination-data';
import { Timeseries } from '../../timeseries/timeseries';

export abstract class AbstractTimeseriesChartNavigationHelper extends ChartPaginationEventsHandler<
  DateRange
> {
  private fromDate: Moment;
  private recordsNum: number;

  data: Timeseries;

  constructor(recordsNum: number) {
    super();
    this.recordsNum = recordsNum;
    this.currentFromDate = this.initFromDate();
  }

  get numberOfRecords(): number {
    return this.recordsNum;
  }

  abstract get dateGranualityUnit(): string;

  public get currentFromDate(): Moment {
    return this.fromDate;
  }

  public set currentFromDate(fromDate: Moment) {
    if (!fromDate) {
      fromDate = this.initFromDate();
    }
    this.fromDate = fromDate;
  }

  /**
   * @return a date range used when a refresh event is triggered
   */
  public abstract getRefreshDateRange(): DateRange;

  protected abstract initFromDate(): Moment;

  abstract isNextEnabled(): boolean;

  abstract isPreviousEnabled(): boolean;

  /**
   * @return a new DateRange if the data must be loaded from the backend service or null if no data loading is required
   * @param event
   */
  abstract handleNextEvent(event: ChartPaginationEvent): DateRange;

  /**
   * @return a new DateRange if the data must be loaded from the backend service or null if no data loading is required
   * @param event
   */
  abstract handlePreviousEvent(event: ChartPaginationEvent): DateRange;
}
