import { Component, Input } from '@angular/core';
import { ScreenService } from '../../core/screen/screen.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-dialog-form.component.html',
  styleUrls: ['./modal-dialog-form.component.scss'],
})
export class ModalDialogFormComponent {
  @Input() title: string;
  @Input() submitAction: ModalDialogAction;
  @Input() resetAction: ModalDialogAction;
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(public screen: ScreenService) {}
}

export class ModalDialogAction {
  label: string;
  handler: () => void;
}
