<ng-template #placeholder>
  <app-placeholder [message]="errorMessage" [error]="error"> </app-placeholder>
</ng-template>

<div *ngIf="data; else placeholder">
  <h2 class="app-title">{{ title }}</h2>

  <app-chart-pagination
    (controlEvents)="handleChartEvent($event)"
    [nextDisabled]="!forwardButtonVisible()"
  ></app-chart-pagination>

  <div class="app-timeseries-chart-labels bold">
    <div class="flex-spacer">kWh</div>
  </div>

  <app-bar-chart
    [records]="recordsToDisplay"
    [chartStepWidths]="chartStepWidths"
    [timeLabelProvider]="timeLabelProvider"
  ></app-bar-chart>
</div>
