import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toNumber } from 'lodash';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  private _value: number = 0;

  @Input()
  min: number = 0;
  @Input()
  max: number = 100;

  @Input()
  get value(): number {
    return this._value;
  }

  @Input()
  placeholder: string;

  set value(val: number) {
    if (val > this.max || this.value < this.min) {
      val = this.min;
    }
    this._value = val;
    this.onChange(this._value);
  }

  @Input()
  unit: string;

  // Function to call when the rating changes.
  onChange = (value: number) => {};
  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean) {}

  onInputChange($event: any) {
    let val: number;
    if ('value' in $event) {
      val = toNumber($event.value);
    } else {
      val = toNumber($event.target.value || this.min);
    }
    if (val != this.value) {
      this.value = val;
    }
  }
}
