import { Component, OnInit } from '@angular/core';
import { ScreenService } from '../../core/screen/screen.service';

@Component({
  selector: 'account-wrapper',
  templateUrl: './account-wrapper.component.html',
  styleUrls: ['./account-wrapper.component.scss'],
})
export class AccountWrapperComponent implements OnInit {
  constructor(public screen: ScreenService) {}

  ngOnInit(): void {}
}
