import { TopToolbarIconBotton } from '../navigation/navigation-data';
import { defaults } from 'lodash';
import { EventEmitter } from '@angular/core';

export interface PageConfiguration {
  showMobileBottomNavbar?: boolean;
  showMobilePageTitle?: boolean;
  hideDesktopNavigationMenu?: boolean;
  hideDesktopAccountMenu?: boolean;

  topLeftToolbarIconButton?: TopToolbarIconBotton;
  topRightToolbarIconButton?: TopToolbarIconBotton;
  /**
   * set to true to hide the margins of the page content container
   */
  useAppFullWidth?: boolean;
  /**
   * a list of classes separated with spaces to be appended to <body> tag when this page is loaded.
   */
  bodyClasses?: string;
}

export function AppPage(pageConfiguration: PageConfiguration) {
  return function (constructor: Function) {
    let parent = Object.getPrototypeOf(constructor);
    parent = parent ? parent.prototype : null;
    let parentConfig = parent ? parent['pageConfiguration'] : {};
    constructor.prototype['pageConfiguration'] = defaults(
      pageConfiguration,
      parentConfig
    );
  };
}

export function getAppPageConfiguration(clazz: any): PageConfiguration | null {
  if (clazz && clazz.prototype && clazz.prototype['pageConfiguration']) {
    return clazz.prototype['pageConfiguration'];
  }
  return null;
}

export class AppPageComponent {
  readonly pageConfigurationChanges: EventEmitter<PageConfiguration> =
    new EventEmitter<PageConfiguration>();

  executeAction(actionName: string, event?: any) {}
}
