import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AddressOverviewData } from '../../card-ordering/card-ordering-data';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataStorageService {
  private PROFILE_KEY = 'CustomerData';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public getCustomerData(): CustomerData {
    return this.storage.get(this.PROFILE_KEY);
  }

  public setCustomerData(customerData: CustomerData) {
    this.storage.set(this.PROFILE_KEY, customerData);
  }

  public remove() {
    this.storage.remove(this.PROFILE_KEY);
  }
}

export interface CompanyData {
  name: string;
  taxId: string;
}

export interface CustomerData {
  customerNumber: string;
  company?: CompanyData;
  shippingAddress?: AddressOverviewData;
}
